<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">
        <el-form-item>
          <el-input v-model="searchForm" placeholder="请输入活动名" style="width:400px"
                    @keyup.enter.native="inquiryActivityProcedure"
                    clearable></el-input>
          <el-button type="primary" @click="inquiryActivityProcedure()">搜索</el-button>
          <el-button type="success" @click="addPageButton()">新增</el-button>
          <el-button type="success" @click="exportButton()">导出</el-button>
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="activityProcedure"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="activityName"
            label="活动名"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="multiplePhotos"-->
<!--            label="多张照片"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="video"
            label="多个视频"
        >
        </el-table-column>
        <el-table-column
            prop="signinStatistics"
            label="签到统计"
        >
        </el-table-column>
        <el-table-column
            prop="activityEvaluation"
            label="活动评价"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="shareAddress"-->
<!--            label="分享地址"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="activityTime"
            label="过程时间"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="updateButton(scope.row.id)">修改</el-button>
            <el-button type="danger" @click="deleteActivityProcedure(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动 : " label-width="100px"  prop="activityName">
                <el-input v-model="ruleForm.activityName" @focus="activityClickOn()"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="过程时间 : " label-width="100px">
                <el-input v-model="ruleForm.activityTime"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="多个视频 : " label-width="100px">
                <el-input v-model="ruleForm.video"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签到统计 : " label-width="100px">
                <el-input v-model="ruleForm.signinStatistics"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动评价 : " label-width="100px">
                <el-input v-model="ruleForm.activityEvaluation"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <h3 style="color: indianred">注：图片请先新增活动后在修改页面中上传</h3>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动 : " label-width="100px"  prop="activityName">
                <el-input v-model="ruleForm.activityName" @focus="activityClickOn()"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="过程时间 : " label-width="100px">
                <el-input v-model="ruleForm.activityTime"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="多个视频 : " label-width="100px">
                <el-input v-model="ruleForm.video"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签到统计 : " label-width="100px">
                <el-input v-model="ruleForm.signinStatistics"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动评价 : " label-width="100px">
                <el-input v-model="ruleForm.activityEvaluation"></el-input>
              </el-form-item>
            </el-col>


          </el-row>
          <h4 style="color: #00a7b8">图片推荐尺寸:宽382px:高215px 、 比例16:9 (上传顺序为小程序显示顺序)</h4>
          <el-row>
            <el-col :span="24">
              <div style="height: 100%;width: 100%;border: 1px solid #D3D3D3; border-radius: 4px;">
                <el-upload
                    :action="UploadUrl()"
                    list-type="picture-card"
                    name="file"
                    :data={id:this.activityProcedureId}
                    :on-preview="handlePictureCardPreview"

                    :file-list="fileList">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updateActivityProcedure()">修改活动过程</el-button>
        </div>
      </el-dialog>
      <el-dialog title="活动信息列表" :visible.sync="activityPage" width="1500px">
        <el-form :inline="true" :model="activityForm" class="demo-form-inline"
                 style="margin-left: 30px;margin-top: 30px;z-index:2">
          <el-form-item>
            <el-select v-model="activityForm.activeStateId" clearable placeholder="活动状态">
              <el-option
                  v-for="item in activeState"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动名">
            <el-input v-model="activityForm.activityName" placeholde1r="请输入姓名" style="width:200px"
                      @keyup.enter.native="activityQuery"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="运维管理员">
            <el-input v-model="activityForm.administrator" placeholde1r="请输入姓名" style="width:100px"
                      @keyup.enter.native="activityQuery"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="发起人">
            <el-input v-model="activityForm.name" placeholde1r="请输入姓名" style="width:100px"
                      @keyup.enter.native="activityQuery"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="活动截止">
            <el-date-picker
                v-model="activityForm.activityTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报名截至时间">
            <el-date-picker
                v-model="activityForm.deadlineRegistrationTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px">
            </el-date-picker>
          </el-form-item>
          <el-button type="primary" @click="activityQuery()">搜索</el-button>
        </el-form>

        <el-table
            border
            :header-cell-style="{background:'#dee2ec', color: '#666666'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            :data="activity"
            stripe
            max-height="750"
            style="width: 100%;margin-bottom:20px">
          <el-table-column
              prop="activityName"
              label="活动名"
          >
          </el-table-column>
          <el-table-column prop="photo" label="图片" width="120">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.photo" fit="cover"></el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="administrator"
              label="运维管理员"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="发起人"
          >
          </el-table-column>
          <el-table-column
              prop="activityTime"
              label="活动截止"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="activeTypeName"
              label="活动类型"
          >
          </el-table-column>
          <el-table-column
              prop="activityLocation"
              label="活动地点"
          >
          </el-table-column>
          <el-table-column
              prop="deadlineRegistrationTime"
              label="报名截至"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="activeStatusName"
              label="活动状态"
          >
          </el-table-column>
          <el-table-column
              prop="maximumNumberPeople"
              label="最大人数"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <el-button type="info" @click="chooseActivity(scope.row.id,scope.row.activityName)">选择</el-button><br/>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: center"
                       background
                       @size-change="handleSizeChange2"
                       @current-change="handleCurrentChange2"
                       :current-page="currentPage2"
                       :page-sizes="pagesizes2"
                       :page-size="pagesize2"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="all2">
        </el-pagination>
      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>1.活动过程页</h2>
          *本页用于对活动过程信息维护，主要专注于活动后的，过程记录。
          <br>
          <h3> 搜索：在上方搜索栏中，模糊输入相关信息后可以进行数据搜索和筛选。<br>
            新增：在上方搜索栏旁有“新增”按钮，点击后进入新增页面<br>
            详情：在每条记录右侧有“详情”按钮，点击后进入详情查看页面，详情查看页面中不能对数据修改。<br></h3>
          <h2>2.活动过程新增和修改页</h2>
          *本页用于对活动信息进行信息维护
          <br>
          <h3> 必填属性：有红色*的为必填属性。<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
      <el-dialog title="导出完成" :visible.sync="exportComplete" width="500px">
        <div>
          <h1><a :href="herf" target="_blank" rel="noopener noreferrer">活动过程下载</a></h1>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="exportComplete = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  inquiryActivityProcedure,
  addActivityProcedure,
  deleteActivityProcedure,
  updateActivityProcedure,
  userActivityModification,
  activityQuery,
  activeState,
  exportActivityProcedure
} from "@/common/js/index.js"
import {isEmpty, setDate, setTime} from "@/common/js/util";

export default {
  name: "activityProcedure",
  data() {
    return {
      exportComplete:false,
      herf:'',
      guidePage:false,
      url:"https://www.scprehabilitation.com/api",
      activityProcedureId:'',
      activityProcedure: [],
      searchForm: '',
      all: 0,
      currentPage: 1,
      loading: false,
      addPage:false,
      updatePage: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        activityId: null,
        activityName: null,
        multiplePhotos: 'null',
        video: null,
        signinStatistics: null,
        activityEvaluation: null,
        shareAddress: null,
        activityTime: null,
        fileList:[]
      },
      rules: {
        activityName: [{required: true, message: '请选择活动',trigger: 'blur'}],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],

      all2: 0,
      currentPage2: 1,
      pagesize2: 20,
      pagesizes2: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      activityPage:false,
      activity:[],
      activityForm: {
        activeStateId: '',
        activityName: '',
        administrator: '',
        name: '',
        activityTime: null,
        deadlineRegistrationTime: null
      },
      activeState: [],
    }
  },
  methods: {
    exportButton(){
      // this.loading = true;
      let subData = 'activityName=' + this.searchForm
      exportActivityProcedure(subData).then(res => {
        if (res) {
          this.herf = res.data;
          this.exportComplete = true
        }

      })

    },
    activityClickOn(){
      this.activityQuery();
      this.activityPage = true;
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
    },
    handleSizeChange2(val) {
      this.pagesize2 = val
    },
    chooseActivity(id,activityName){
      this.ruleForm.activityName = activityName;
      this.ruleForm.activityId = id;
      this.activityPage = false;
    },
    activityQuery() {
      let subData = 'currPage=' + this.currentPage2
          + '&pageSizePara=' + this.pagesize2
          + '&status=' + this.activityForm.activeStateId
          + '&activityName=' + this.activityForm.activityName
          + '&administrator=' + this.activityForm.administrator
          + '&name=' + this.activityForm.name
      if (this.activityForm.activityTime != null) {
        let searchStartTime = setDate(this.activityForm.activityTime[0])
        let searchEndTime = setDate(this.activityForm.activityTime[1])
        subData += '&activityTimeBegin=' + searchStartTime + '&activityTimeEnd=' + searchEndTime + ' 23:59:59'
      }
      if (this.activityForm.deadlineRegistrationTime != null) {
        let searchStartTime = setDate(this.activityForm.deadlineRegistrationTime[0])
        let searchEndTime = setDate(this.activityForm.deadlineRegistrationTime[1])
        subData += '&deadlineRegistrationTimeBegin=' + searchStartTime + '&deadlineRegistrationTimeEnd=' + searchEndTime + ' 23:59:59'
      }
      activityQuery(subData).then(res => {
        if (res) {
          this.activity = res.data
          if (res.data.length !== 0) {
            this.all2 = res.data.length
          } else {
            this.all2 = 0
          }
          this.loading = true
        }

      })

    },
    UploadUrl:function(){
      return this.url+"/xkyActivityProcedure/uploadMultiplePhotos";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    updateButton(id){
      this.updatePage = true;
      for (let i = 0; i < this.activityProcedure.length; i++) {
        if (id === this.activityProcedure[i].id){
          this.ruleForm = this.activityProcedure[i]
          this.activityProcedureId = id
          this.fileList = this.ruleForm.fileList;
        }
      }
    },
    updateActivityProcedure(){
      let subData = this.getFormData()
      updateActivityProcedure(subData).then(res => {
        if (res) {
          this.$message.success('修改成功');
          this.inquiryActivityProcedure()
          this.updatePage = false
        }

      })
    },
    deleteActivityProcedure(id){
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deleteActivityProcedure(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.inquiryActivityProcedure()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton(){
      this.addPage = true
      this.ruleForm = {
        activityId: null,
        activityName: null,
        multiplePhotos: 'null',
        video: null,
        signinStatistics: null,
        activityEvaluation: null,
        shareAddress: null,
        activityTime: null,
        fileList:[]
      }
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.activityId) && subData.append("activityId", this.ruleForm.activityId);
      isEmpty(this.ruleForm.multiplePhotos) && subData.append("multiplePhotos", this.ruleForm.multiplePhotos);
      isEmpty(this.ruleForm.video) && subData.append("video", this.ruleForm.video);
      isEmpty(this.ruleForm.signinStatistics) && subData.append("signinStatistics", this.ruleForm.signinStatistics);
      isEmpty(this.ruleForm.activityEvaluation) && subData.append("activityEvaluation", this.ruleForm.activityEvaluation);
      isEmpty(this.ruleForm.activityTime) && subData.append("activityTime", this.ruleForm.activityTime);
      return subData;
    },
    add(ruleForm){

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData()
          addActivityProcedure(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.inquiryActivityProcedure()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    inquiryActivityProcedure() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&activityName=' + this.searchForm
      inquiryActivityProcedure(subData).then(res => {
        if (res) {
          this.activityProcedure = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
    selectActiveState() {
      activeState().then(res => {
        if (res) {
          this.activeState = res.data
        }
      })
    },
  },
  created() {
    this.inquiryActivityProcedure();
    this.selectActiveState();
  },
  watch: {
    currentPage: function () {
      this.inquiryActivityProcedure()
    },
    pagesize: function () {
      this.inquiryActivityProcedure()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
