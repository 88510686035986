<template>
  <div class="main-box">
    <div class="login-top">
      <div class="logo-box">
        <div class="logo-right"><img src="@/assets/logo_a.png" class="img-top"></div>
      </div>
    </div>
    <div class="login-center">
      <div class="center-img"><img src="@/assets/logo_b.png" alt=""></div>
      <div class="login-box" v-show="isShow===0">
        <span class="title">账号登录</span>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入账号" class="line" clearable></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input type="password" v-model="ruleForm.pwd" placeholder="请输入密码" clearable class=" line"
                      @keyup.enter.native.stop="loginForm('ruleForm')"></el-input>
          </el-form-item>
          <div>
            <el-button type="primary" @click.stop="loginForm('ruleForm')">登录</el-button>
          </div>

        </el-form>
      </div>

    </div>
    <div style="margin-top: 200px;text-align:center;">
      <span> </span><br>
      <span><p style="color: #8c939d">ICP证：沪ICP备19012312号-7</p></span>
    </div>
    <remote-js src="http://pv.sohu.com/cityjson?ie=utf-8"></remote-js>
  </div>

</template>
<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>
<script>
import {login} from "@/common/js/index.js"

export default {
  name: "login",
  data() {
    return {
      siteValue: "",
      ruleForm: {
        name: "",
        pwd: "",
      },
      isShow: 0,
      rules: {
        name: [{required: true, message: '请输入账号', trigger: 'blur'}],
        pwd: [{required: true, message: '请输入密码', trigger: 'blur'}],
      },
    }
  },
  methods: {
    loginForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ip = '127.0.0.1'
          try {
            ip = returnCitySN.cip
          } catch (e) {
            console.log("登陆获取ip错误:" + e);
          }
          console.log("ip" + ip)
          let subData = "name=" + this.ruleForm.name + "&password=" + this.ruleForm.pwd + "&ip="+ip
          login(subData).then(res => {
            if (res) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("sessionid", res.data.sessionId);
              localStorage.setItem("systemUser", res.data.systemAccount[0].name);
              localStorage.setItem("systemUserId", res.data.systemAccount[0].id);
              this.$message.success('登录成功');
              this.$router.push({
                name: 'home'
              })
            }
          })
        } else {
          return false;
        }
      });
    },
  },
  components: {
    'remote-js': {
      render(createElement) {
        return createElement('script', {attrs: {type: 'text/javascript', src: this.src}});
      },
      props: {
        src: {type: String, required: true},
      },
    },
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 40px;
}

.login-top {
  height: 100px;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  padding: 0 70px;
  align-items: center;
}

.logo-right {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
}

.logo-box {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  width: 200px;
  align-items: center;
  /*  margin-left: 30px;*/

}

.logo-box .logo-left {
  width: 58px;
  height: 58px;
}

.logo-right .img-top {
  width: 105px;
  height: 45px;
}

.logo-right .img-bottom {
  width: 130px;
  height: 20px;
}

.phone {
  font-size: 16px;
  color: hsla(0, 0%, 43%, .9);
}

.login-center {
  /*  padding: 0 250px;*/
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

.center-img {
  width: 500px;
  height: 100%;
}

.login-box {
  width: 480px;
  height: 550px;
  padding: 40px;
  color: #00a7b8;
  /*display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;*/
  background: #fff;
  border-radius: 6px;
  font-size: 16px;
  box-shadow: 0 0 50px -10px #00a7b8;
}

.login-box .title {
  color: #00a7b8;
  line-height: 42px;
  font-size: 30px;
}

.demo-ruleForm {
  margin-top: 50px;
}

.input {
  width: 240px;
}

.el-button {
  width: 100%;
  height: 50px;
  margin-top: 30px;
}

.login-title {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
}

/*.login-box .login-title .title-right span{
        font-size: 16px;
    }*/
.pwd-opera {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
}

.el-switch {
  margin-left: 10px;
}

div {
  box-sizing: border-box;
}
</style>
