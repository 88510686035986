<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div>
      <el-button type="primary" @click="refresh()">刷新</el-button>
      <el-button type="success" @click="add()">新增</el-button>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-row :gutter="0">
            <el-col :span="18">
              <h1>活动状态字典</h1>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>

          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="activeState"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="状态名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,1)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <h1>活动类型字典</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="activeType"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="类型名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,2)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,2)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <h1>行业领域字典</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="industrySector"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="行业名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,3)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,3)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-row :gutter="0">
            <el-col :span="18">
              <h1>会员等级字典</h1>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>

          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="membershipLevel"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="level"
                label="会员等级"
            >
            </el-table-column>
            <el-table-column
                prop="price"
                label="价格"
                width="100px"
            >
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="integral"-->
<!--                label="积分"-->
<!--                width="100px"-->
<!--            >-->
<!--            </el-table-column>-->
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.level,4,scope.row.level,scope.row.price)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,4)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <h1>订单状态字典</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="orderStatus"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="状态名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,5)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,5)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <h1>积分类型字典</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="pointsType"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="类型名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,6)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,6)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-row :gutter="0">
            <el-col :span="18">
              <h1>主题状态</h1>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>

          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="topicStatus"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="状态名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,7)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,7)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="0">
            <el-col :span="18">
              <h1>咖指</h1>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>

          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="points"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="操作"
            >
            </el-table-column>
            <el-table-column
                prop="points"
                label="积分"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,8)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,8)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="0">
            <el-col :span="18">
              <h1>积分变更状态</h1>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>

          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="integralChangeStatus"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="状态名
"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,9)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,9)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <h1>报名状态</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="registrationStatus"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="类型名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,10)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,10)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <h1>账户状态</h1>
          <el-table
              border
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
              :data="userStatus"
              stripe
              max-height="750"
              style="width: 100%;margin-bottom:20px">
            <el-table-column
                prop="name"
                label="状态名"
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="updateButton(scope.row.id,scope.row.name,11)">修改</el-button>
                <el-button type="danger" @click="deleteDictionary(scope.row.id,11)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog title="新增" :visible.sync="addPage" width="500px">
        <el-form>
          <el-select v-model="from.value" clearable placeholder="请选择" @change="typeChange">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

          <el-form-item label="字典名 : " v-if="status_1">
            <el-input v-model="from.name"></el-input>
          </el-form-item>

          <el-form-item label="会员等级 : " v-if="status_2">
            <el-input v-model="from.level"></el-input>
          </el-form-item>
          <el-form-item label="价格 : " v-if="status_2">
            <el-input v-model="from.price"></el-input>
          </el-form-item>
          <el-form-item label="咖指名 : " v-if="status_3">
            <el-input v-model="from.name"></el-input>
          </el-form-item>
          <el-form-item label="积分 : " v-if="status_3">
            <el-input v-model="from.points"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="addDictionary()">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="500px">
        <el-form>
          <el-form-item label="字典名 : ">
            <el-input v-model="from.name" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="新字典名 : "  v-if="status_1">
            <el-input v-model="updateFrom.name"></el-input>
          </el-form-item>
          <el-form-item label="会员等级 : " v-if="status_2">
            <el-input v-model="updateFrom.level"></el-input>
          </el-form-item>
          <el-form-item label="价格 : " v-if="status_2">
            <el-input v-model="updateFrom.price"></el-input>
          </el-form-item>
          <el-form-item label="咖指名 : " v-if="status_3">
            <el-input v-model="updateFrom.name"></el-input>
          </el-form-item>
          <el-form-item label="积分 : " v-if="status_3">
            <el-input v-model="updateFrom.points"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="modifyOK()">修 改</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import {
  activeState,
  activeType,
  industrySector,
  membershipLevel,
  orderStatus,
  pointsType,
  topicStatus,
  points,
  addActiveState,
  addActiveType,
  addIndustrySector,
  addMembershipLevel,
  addOrderStatus,
  addPointsType,
  addTopicStatus,
  addPoints,
  updateActiveState,
  updateActiveType,
  updateIndustrySector,
  updateMembershipLevel,
  updateOrderStatus,
  updatePointsType,
  updateTopicStatus,
  updatePoints,
  deleteActiveState,
  deleteActiveType,
  deleteIndustrySector,
  deleteMembershipLevel,
  deleteOrderStatus,
  deletePointsType,
  deleteTopicStatus,
  deletePoints,
  integralChangeStatus, addIntegralChangeStatus, updateIntegralChangeStatus, deleteIntegralChangeStatus,registrationStatus,addRegistrationStatus,
  upRegistrationStatus,
  deRegistrationStatus,
  dictionaryUserStatus,
  insertDictionaryUserStatus,
  updateDictionaryUserStatus,
  deleteDictionaryUserStatus
} from "@/common/js/index.js"

export default {
  name: 'dictionary',
  data() {
    return {
      formLabelWidth: '120px',
      activeState: [], // 活动状态字典
      activeType: [], // 活动类型字典
      industrySector: [], // 行业领域字典
      membershipLevel: [], // 会员等级字典
      orderStatus: [], // 订单状态字典
      pointsType: [], // 积分类型字典
      points: [],
      topicStatus:[],
      integralChangeStatus:[],
      registrationStatus:[],
      userStatus:[],
      addPage: false,
      updatePage: false,
      status_1: false,
      status_2: false,
      status_3: false,
      options: [{
        value: '选项1',
        label: '活动状态'
      }, {
        value: '选项2',
        label: '活动类型'
      }, {
        value: '选项3',
        label: '行业领域'
      }, {
        value: '选项4',
        label: '会员等级'
      }, {
        value: '选项5',
        label: '订单状态'
      }, {
        value: '选项6',
        label: '积分类型'
      }, {
        value: '选项7',
        label: '主题状态'
      }, {
          value: '选项8',
          label: '咖指'
      }, {
        value: '选项9',
        label: '积分变更状态'
      }, {
        value: '选项10',
        label: '报名状态'
      }, {
        value: '选项11',
        label: '账户状态'
      }],
      from: {
        value: '',
        name: '',
        level: '',
        price: '',
        integral: 0,
        points:0
      },
      updateFrom: {
        id: '',
        name: '',
        level: '',
        price: '',
        integral: 0,
        type: '', // 字典类型
        points:0
      },

    }
  },
  methods: {
    deleteDictionary(id,type){
      this.$confirm('此操作将永久删除该字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        if (type === 1) {
          deleteActiveState(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 2) {
          deleteActiveType(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 3) {
          deleteIndustrySector(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 4) {
          deleteMembershipLevel(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 5) {
          deleteOrderStatus(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除成功',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 6) {
          deletePointsType(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 7) {
          deleteTopicStatus(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 8) {
          deletePoints(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 9) {
          deleteIntegralChangeStatus(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 10) {
          deRegistrationStatus(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        } else if (type === 11) {
          deleteDictionaryUserStatus(subData).then(res => {
            if (res) {
              this.$message({
                showClose: true,
                message: '删除',
                type: 'success'
              });
              this.refresh()
            }
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    updateButton(id, name, type,level,price) {

      console.log(type)


      this.updatePage = true
      this.updateFrom.id = id
      this.updateFrom.type = type
      this.updateFrom.level = level
      this.updateFrom.price = price
      this.updateFrom.price = price
      this.from.name = name
      this.from.level = level
      this.from.price = price

      if (type === 4) {
        this.status_1 = false
        this.status_3 = false
        this.status_2 = true

      } else if (type === 8){
        this.status_1 = false
        this.status_2 = false
        this.status_3 = true
      }else {
        this.status_1 = true
        this.status_2 = false
        this.status_3 = false
      }


    },
    modifyOK() {
      let subData = "id=" + this.updateFrom.id + "&name=" + this.updateFrom.name + "&level=" + this.updateFrom.level + "&price=" + this.updateFrom.price
          + "&integral=" + this.updateFrom.integral+ "&points=" + this.updateFrom.points




      if (this.updateFrom.type === 1) {
        updateActiveState(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 2) {
        updateActiveType(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 3) {
        updateIndustrySector(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 4) {
        updateMembershipLevel(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 5) {
        updateOrderStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 6) {
        updatePointsType(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 7) {
        updateTopicStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 8) {
        updatePoints(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 9) {
        updateIntegralChangeStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 10) {
        upRegistrationStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.updateFrom.type === 11) {
        updateDictionaryUserStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      }
    },
    addDictionary() {
      let subData = "name=" + this.from.name + "&level=" + this.from.level + "&price=" + this.from.price
          + "&integral=" + this.from.integral + '&points=' + this.from.points
      if (this.from.value === "选项1") {
        addActiveState(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项2") {
        addActiveType(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项3") {
        addIndustrySector(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项4") {
        addMembershipLevel(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项5") {
        addOrderStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项6") {
        addPointsType(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项7") {
        addTopicStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项8") {
        addPoints(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项9") {
        addIntegralChangeStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项10") {
        addRegistrationStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      } else if (this.from.value === "选项11") {
        insertDictionaryUserStatus(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.refresh()
          }
        })
      }

    },
    add() {
      this.addPage = true;
    },
    typeChange() {
      if (this.from.value === "选项4") {
        this.status_1 = false
        this.status_3 = false
        this.status_2 = true

      } else if (this.from.value === "选项8"){
        this.status_1 = false
        this.status_2 = false
        this.status_3 = true
      }else {
        this.status_1 = true
        this.status_2 = false
        this.status_3 = false
      }
    },
    refresh() {
      this.selectActiveState()
      this.selectActiveType()
      this.selectRegistrationStatus()
      this.selectIndustrySector()
      this.selectMembershipLevel()
      this.selectOrderStatus()
      this.selectPointsType()
      this.selectTopicStatus()
      this.selectPoints()
      this.selectIntegralChangeStatus()
      this.selectIntegralChangeStatus()
      this.dictionaryUserStatus()
      this.addPage = false
      this.updatePage = false
    },
    selectTopicStatus() {
      topicStatus().then(res => {
        if (res) {
          this.topicStatus = res.data
        }
      })
    },
    selectActiveState() {
      activeState().then(res => {
        if (res) {
          this.activeState = res.data
        }
      })
    },
    selectActiveType() {
      activeType().then(res => {
        if (res) {
          this.activeType = res.data
        }
      })
    },
    selectRegistrationStatus() {
      registrationStatus().then(res => {
        if (res) {
          this.registrationStatus = res.data
        }
      })
    },
    selectIndustrySector() {
      industrySector().then(res => {
        if (res) {
          this.industrySector = res.data
        }
      })
    },
    selectMembershipLevel() {
      membershipLevel().then(res => {
        if (res) {
          this.membershipLevel = res.data
        }
      })
    },
    selectOrderStatus() {
      orderStatus().then(res => {
        if (res) {
          this.orderStatus = res.data
        }
      })
    },
    selectPointsType() {
      pointsType().then(res => {
        if (res) {
          this.pointsType = res.data
        }
      })
    },
    dictionaryUserStatus() {
      dictionaryUserStatus().then(res => {
        if (res) {
          this.userStatus = res.data
        }
      })
    },
    selectPoints() {
      points().then(res => {
        if (res) {
          this.points = res.data
        }
      })
    },
    selectIntegralChangeStatus() {
      integralChangeStatus().then(res => {
        if (res) {
          this.integralChangeStatus = res.data
        }
      })
    },
  },
  computed: {},
  created() {
    this.selectActiveState()
    this.selectActiveType()
    this.selectRegistrationStatus()
    this.selectIndustrySector()
    this.selectMembershipLevel()
    this.selectOrderStatus()
    this.selectPointsType()
    this.selectTopicStatus()
    this.selectPoints()
    this.selectIntegralChangeStatus()
    this.dictionaryUserStatus()
  },
}
</script>
<style scoped lang="scss">
</style>
