<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline"
               style="margin-left: 30px;margin-top: 30px;z-index:2">
        <el-form-item>
          <el-select v-model="searchForm.activeStateId" clearable placeholder="活动状态" style="width: 120px;">
            <el-option
                v-for="item in activeState"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名">
          <el-input v-model="searchForm.activityName" placeholde1r="请输入姓名" style="width:200px"
                    @keyup.enter.native="activityQuery"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="运维管理员">
          <el-input v-model="searchForm.administrator" placeholde1r="请输入姓名" style="width:100px"
                    @keyup.enter.native="activityQuery"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="发起人">
          <el-input v-model="searchForm.name" placeholde1r="请输入姓名" style="width:100px"
                    @keyup.enter.native="activityQuery"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="活动开始">
          <el-date-picker
              v-model="searchForm.activityTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 230px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报名截至时间">
          <el-date-picker
              v-model="searchForm.deadlineRegistrationTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 230px">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="activityQuery()">搜索</el-button>
        <el-button type="success" @click="addPageButton()">新增</el-button>
        <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>

      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="activity"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="activityName"
            label="活动名"
        >
        </el-table-column>
        <el-table-column prop="photo" label="图片" width="120">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.photo" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="administrator"
            label="运维管理员"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="发起人"
        >
        </el-table-column>
        <el-table-column
            prop="organizer"
            label="联系人"
        >
        </el-table-column>
        <el-table-column
            prop="contactInformation"
            label="联系方式"
        >
        </el-table-column>
        <el-table-column
            prop="activityDescription"
            label="活动介绍"
            width="350px"
        >
        </el-table-column>
        <el-table-column
            prop="activityTime"
            label="活动开始"
            width="100px"
        >
        </el-table-column>
        <el-table-column
            prop="activeTypeName"
            label="活动类型"
        >
        </el-table-column>
        <el-table-column
            prop="activityLocation"
            label="活动地点"
        >
        </el-table-column>
        <el-table-column
            prop="deadlineRegistrationTime"
            label="报名截至"
            width="100px"
        >
        </el-table-column>
        <el-table-column
            prop="maximumNumberPeople"
            label="最大人数"
        >
        </el-table-column>
        <el-table-column
            prop="peopleCounting"
            label="人数统计"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="cost"-->
<!--            label="费用"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="activeStatusName"
            label="活动状态"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="info" @click="detailedButton(scope.row.id)">详情</el-button><br/>
            <el-button type="primary" @click="updateButton(scope.row.id)">修改</el-button><br/>
            <el-button type="danger" @click="deleteActivity(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="详情" :visible.sync="detailedPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动名 : " label-width="100px"  prop="activityName">
                <el-input v-model="ruleForm.activityName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运维管理员 : " label-width="120px">
                <el-input v-model="ruleForm.administrator" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发起人 : " label-width="100px" prop="userId">
                <el-input v-model="ruleForm.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="活动介绍 : " label-width="100px">
                <el-input v-model="ruleForm.activityDescription" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动联系人 : " label-width="100px" prop="userId">
                <el-input v-model="ruleForm.organizer" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式 : " label-width="100px">
                <el-input v-model="ruleForm.contactInformation" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="活动开始 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.activityTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    disabled>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动类型 : " label-width="100px" prop="activityType">
                <el-select v-model="ruleForm.activityType" clearable placeholder="活动类型" disabled>
                <el-option
                    v-for="item in activeType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动状态 : " label-width="100px"  prop="status">
                <el-select v-model="ruleForm.status" clearable placeholder="活动类型" disabled>
                  <el-option
                      v-for="item in activeState"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动地点 : " label-width="100px">
                <el-input v-model="ruleForm.activityLocation" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名截至时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.deadlineRegistrationTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    disabled>
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="最大人数 : " label-width="100px">
                <el-input v-model="ruleForm.maximumNumberPeople" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="嘉宾介绍 : " label-width="100px">
                <el-input v-model="ruleForm.guestIntroduction" disabled></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="费用 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.cost" disabled></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="分享地址 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.shareAddress" disabled></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

          </el-row>
          <el-row>
            <el-col :span="4">
              <h2>活动图片</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
                  disabled
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>

        </el-form>
      </el-dialog>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动名 : " label-width="100px"  prop="activityName">
                <el-input v-model="ruleForm.activityName" maxlength="30"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运维管理员 : " label-width="120px">
                <el-input v-model="ruleForm.administrator"  @focus="administratorClickOn"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发起人 : " label-width="100px" prop="userId" >
                <el-input v-model="ruleForm.name" @focus="userClickOn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="活动介绍 : " label-width="100px">
                <el-input v-model="ruleForm.activityDescription"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动联系人 : " label-width="100px" >
                <el-input v-model="ruleForm.organizer" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式 : " label-width="100px">
                <el-input v-model="ruleForm.contactInformation"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="活动开始 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.activityTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动类型 : " label-width="100px" prop="activityType">
                <el-select v-model="ruleForm.activityType" clearable placeholder="活动类型">
                <el-option
                    v-for="item in activeType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动状态 : " label-width="100px"  prop="status">
                <el-select v-model="ruleForm.status" clearable placeholder="活动状态">
                  <el-option
                      v-for="item in activeState"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动地点 : " label-width="100px">
                <el-input v-model="ruleForm.activityLocation"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名截至时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.deadlineRegistrationTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="最大人数 : " label-width="100px">
                <el-input v-model="ruleForm.maximumNumberPeople"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="嘉宾介绍 : " label-width="100px">
                <el-input v-model="ruleForm.guestIntroduction"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="费用 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.cost"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="分享地址 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.shareAddress"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

          </el-row>
          <h4 style="color: #00a7b8">图片推荐尺寸:宽415px:高311px 、比例4:3 (图片下方会有22px遮挡)</h4>
          <el-row>
            <el-col :span="4">
              <h2>活动图片</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动名 : " label-width="100px"  prop="activityName">
                <el-input v-model="ruleForm.activityName"  maxlength="30"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运维管理员 : " label-width="120px">
              <el-input v-model="ruleForm.administrator"  @focus="administratorClickOn"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="发起人 : " label-width="100px" prop="userId">
                <el-input v-model="ruleForm.name"  @focus="userClickOn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="活动介绍 : " label-width="100px">
                <el-input v-model="ruleForm.activityDescription"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动联系人 : " label-width="100px" >
                <el-input v-model="ruleForm.organizer" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式 : " label-width="100px">
                <el-input v-model="ruleForm.contactInformation"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="活动开始 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.activityTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动类型 : " label-width="100px" prop="activityType">
                <el-select v-model="ruleForm.activityType" clearable placeholder="活动类型">
                  <el-option
                      v-for="item in activeType"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动状态 : " label-width="100px"  prop="status">
                <el-select v-model="ruleForm.status" clearable placeholder="活动类型">
                  <el-option
                      v-for="item in activeState"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动地点 : " label-width="100px">
                <el-input v-model="ruleForm.activityLocation"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名截至时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.deadlineRegistrationTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="最大人数 : " label-width="100px">
                <el-input v-model="ruleForm.maximumNumberPeople"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="嘉宾介绍 : " label-width="100px">
                <el-input v-model="ruleForm.guestIntroduction"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="费用 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.cost"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="分享地址 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.shareAddress"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

          </el-row>
          <h4 style="color: #00a7b8">图片推荐尺寸:宽415px:高311px 、比例4:3 (图片下方会有22px遮挡)</h4>
          <el-row>
            <el-col :span="4">
              <h2>活动图片</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>

        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="userActivityModification()">修改活动信息</el-button>
        </div>
      </el-dialog>
      <el-dialog title="用户列表" :visible.sync="userPage" width="1500px">
        <el-input v-model="userForm" placeholder="请输入姓名、微信号、微信手机号、真实姓名" style="width:400px"
                  @keyup.enter.native="queryUser"
                  clearable></el-input>
        <el-table
            border
            :header-cell-style="{background:'#dee2ec', color: '#666666'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            :data="userInfo"
            stripe
            max-height="750"
            style="width: 100%;margin-bottom:20px">
          <el-table-column
              prop="id"
              label="用户ID"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="wechatPhone"
              label="微信手机号"
              width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
          >
          </el-table-column>
          <el-table-column
              prop="membershipLevelId"
              label="会员等级"
          >
          </el-table-column>
          <el-table-column
              prop="sex"
              label="性别"
          >
          </el-table-column>
          <el-table-column
              prop="industrySectorId"
              label="行业领域"
          >
          </el-table-column>
          <el-table-column
              prop="affiliatedCompany"
              label="所属企业"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="info" @click="chooseUser(scope.row.id,scope.row.name)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: center"
                       background
                       @size-change="handleSizeChange2"
                       @current-change="handleCurrentChange2"
                       :current-page="currentPage2"
                       :page-sizes="pagesizes2"
                       :page-size="pagesize2"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="all2">
        </el-pagination>


      </el-dialog>
      <el-dialog title="管理员列表" :visible.sync="administratorPage" width="800px">
        <el-table
            border
            :header-cell-style="{background:'#dee2ec', color: '#666666'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            :data="administrators"
            stripe
            max-height="750"
            style="width: 100%;margin-bottom:20px">
          <el-table-column
              prop="id"
              label="管理员ID"
          >
          </el-table-column>
          <el-table-column
              prop="roles"
              label="角色"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="名字"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="info" @click="chooseAdministrator(scope.row.id,scope.row.name)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>1.活动信息页</h2>
          *本页用于对活动信息信息维护，主要专注于活动的申请、组织、报名阶段。
          <br>
          <h3> 搜索：在上方搜索栏中，勾选状态、模糊输入相关信息、等操作后可以进行数据搜索和筛选。<br>
            新增：在上方搜索栏旁有“新增”按钮，点击后进入新增页面<br>
            详情：在每条记录右侧有“详情”按钮，点击后进入详情查看页面，详情查看页面中不能对用户进行数据修改。<br>
            修改：在每条记录右侧有“修改”按钮，点击后进入修改修改页面<br>
            删除：在每条记录右侧有“删除”按钮，点击后进入删除页面，点击后会弹出确认窗口，按“确认”完成删除。<br></h3>
          <h2>2.活动信息新增和修改页</h2>
          *本页用于对活动信息进行信息维护
          <br>
          <h3> 必填属性：有红色*的为必填属性。<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  activityQuery, activeState, activeType, activityApplication, deleteActivity, userActivityModification, queryUser,administratorList
} from "@/common/js/index.js"
import {isEmpty, setDate, setTime} from '@/common/js/util.js'

export default {
  name: "activityQuery",
  data() {
    return {
      guidePage:false,
      idBacUrl: "",
      activity: [],
      activeState: [],
      activeType: [],
      searchForm: {
        activeStateId: '',
        activityName: '',
        administrator: '',
        name: '',
        activityTime: null,
        deadlineRegistrationTime: null
      },
      userForm: '',
      date: '',
      all: 0,
      currentPage: 1,
      all2: 0,
      currentPage2: 1,
      loading: false,
      addPage: false,
      detailedPage: false,
      updatePage: false,
      userPage: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      pagesize2: 20,
      pagesizes2: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        activityName: null,
        photoSmall: null,
        photo: null,
        administratorId: null,
        administrator:"",
        userId: null,
        name: null,
        activityDescription: null,
        activityTime: null,
        activityType: '',
        activityLocation: null,
        longitude: null,
        latitude: null,
        deadlineRegistrationTime: null,
        maximumNumberPeople: null,
        cost: null,
        shareAddress: null,
        guestIntroduction: null,
        status: '',
        organizer:'',
        contactInformation:'',

      },
      rules: {
        activityName: [{required: true,message: '请选择活动名', trigger: 'blur'}],
        userId: [{required: true, message: '请选择发起人',trigger: 'blur'}],
        status: [{required: true, message: '请选择活动状态', trigger: 'blur'}],
        activityType: [{required: true, message: '请选择活动类型', trigger: 'blur'}],
      },
      userInfo: [],
      administrators:[],
      administratorPage:false
    }
  },
  methods: {
    chooseUser(id,name){
      this.ruleForm.name = name;
      this.ruleForm.userId = id;
      this.userPage = false;
    },
    chooseAdministrator(id,name){
      this.ruleForm.administrator = name;
      this.ruleForm.administratorId = id;
      this.administratorPage = false;
    },
    administratorClickOn(){
      this.queryAdministratorList();
      this.administratorPage = true;
    },
    queryAdministratorList() {
      let subData
      administratorList(subData).then(res => {
        if (res) {
          this.administrators = res.data

          this.loading = true
        }

      })

    },
    userClickOn(){
      this.queryUser();
      this.userPage = true;
    },
    queryUser() {
      let subData = 'currPage=' + this.currentPage2
          + '&pageSizePara=' + this.pagesize2
          + '&name=' + this.userForm
      queryUser(subData).then(res => {
        if (res) {
          this.userInfo = res.data
          if (res.data.length !== 0) {
            this.all2 = res.data.length
          } else {
            this.all2 = 0
          }
          this.loading = true
        }

      })

    },
    onUploadChange(file) {
      this.idBacUrl = URL.createObjectURL(file.raw);
    },
    detailedButton(id){
      this.detailedPage = true;
      for (let i = 0; i < this.activity.length; i++) {
        if (id === this.activity[i].id){
          this.ruleForm = this.activity[i]
          this.idBacUrl = this.ruleForm.photo
        }
      }
    },
    updateButton(id){
      this.updatePage = true;
      for (let i = 0; i < this.activity.length; i++) {
        if (id === this.activity[i].id){
          this.ruleForm = this.activity[i]
          this.idBacUrl = this.ruleForm.photo
        }
      }
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.activityName) && subData.append("activityName", this.ruleForm.activityName);
      isEmpty(this.ruleForm.administratorId) && subData.append("administratorId", this.ruleForm.administratorId);
      isEmpty(this.ruleForm.wechatPhone) && subData.append("wechatPhone", this.ruleForm.wechatPhone);
      isEmpty(this.ruleForm.userId) && subData.append("userId", this.ruleForm.userId);
      isEmpty(this.ruleForm.activityDescription) && subData.append("activityDescription", this.ruleForm.activityDescription);
      isEmpty(this.ruleForm.activityType) && subData.append("activityType", this.ruleForm.activityType);
      isEmpty(this.ruleForm.activityLocation) && subData.append("activityLocation", this.ruleForm.activityLocation);
      isEmpty(this.ruleForm.maximumNumberPeople) && subData.append("maximumNumberPeople", this.ruleForm.maximumNumberPeople);
      isEmpty(this.ruleForm.cost) && subData.append("cost", this.ruleForm.cost);
      isEmpty(this.ruleForm.shareAddress) && subData.append("shareAddress", this.ruleForm.shareAddress);
      isEmpty(this.ruleForm.guestIntroduction) && subData.append("guestIntroduction", this.ruleForm.guestIntroduction);
      isEmpty(this.ruleForm.status) && subData.append("status", this.ruleForm.status);
      isEmpty(this.ruleForm.activityTime) && subData.append("addActivityTime", setTime(this.ruleForm.activityTime));
      isEmpty(this.ruleForm.deadlineRegistrationTime) && subData.append("addDeadlineRegistrationTime", setTime(this.ruleForm.deadlineRegistrationTime));
      isEmpty(this.ruleForm.organizer) && subData.append("organizer", this.ruleForm.organizer);
      isEmpty(this.ruleForm.contactInformation) && subData.append("contactInformation", this.ruleForm.contactInformation);
      let imgUpload = this.$refs.imgUpload.uploadFiles;
      if (imgUpload !== null && imgUpload.length !== 0) {
        subData.append("img", imgUpload[imgUpload.length - 1].raw);
      }
      return subData;
    },
    userActivityModification(){
      let subData = this.getFormData();
      userActivityModification(subData).then(res => {
        if (res) {
          this.$message.success('修改成功');
          this.activityQuery()
          this.updatePage = false
        }

      })
    },
    deleteActivity(id){
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deleteActivity(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.activityQuery()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton(){
      this.addPage = true
      this.ruleForm =  {
        activityName: null,
        photoSmall: null,
        photo: null,
        administratorId: null,
        administrator:"",
        userId: null,
        name: null,
        activityDescription: null,
        activityTime: null,
        activityType: '',
        activityLocation: null,
        longitude: null,
        latitude: null,
        deadlineRegistrationTime: null,
        maximumNumberPeople: null,
        cost: null,
        shareAddress: null,
        guestIntroduction: null,
        status: '',
        organizer:'',
        contactInformation:''
      }
      this.idBacUrl = ""
    },
    add(ruleForm){
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData();
          activityApplication(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.activityQuery()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    activityQuery() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&status=' + this.searchForm.activeStateId
          + '&activityName=' + this.searchForm.activityName
          + '&administrator=' + this.searchForm.administrator
          + '&name=' + this.searchForm.name
      if (this.searchForm.activityTime != null) {
        let searchStartTime = setDate(this.searchForm.activityTime[0])
        let searchEndTime = setDate(this.searchForm.activityTime[1])
        subData += '&activityTimeBegin=' + searchStartTime + '&activityTimeEnd=' + searchEndTime + ' 23:59:59'
      }
      if (this.searchForm.deadlineRegistrationTime != null) {
        let searchStartTime = setDate(this.searchForm.deadlineRegistrationTime[0])
        let searchEndTime = setDate(this.searchForm.deadlineRegistrationTime[1])
        subData += '&deadlineRegistrationTimeBegin=' + searchStartTime + '&deadlineRegistrationTimeEnd=' + searchEndTime + ' 23:59:59'
      }
      activityQuery(subData).then(res => {
        if (res) {
          this.activity = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    selectActiveState() {
      activeState().then(res => {
        if (res) {
          this.activeState = res.data
        }
      })
    },
    selectActiveType() {
      activeType().then(res => {
        if (res) {
          this.activeType = res.data
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
    },
    handleSizeChange2(val) {
      this.pagesize2 = val
    },
  },
  created() {
    this.activityQuery();
    this.selectActiveState();
    this.selectActiveType();
  },
  watch: {
    currentPage: function () {
      this.activityQuery()
    },
    pagesize: function () {
      this.activityQuery()
    },
  }
}
</script>

<style scoped>
/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
