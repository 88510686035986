<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
    export default {
        name: 'app',
        provide () {
            return {
                reload: this.reload
            }
        },
        data () {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            reload () {
                this.isRouterAlive = false
                this.$nextTick(function () {
                    this.isRouterAlive = true
                })
            }
        }
    }
</script>
<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  font-size: 16px;
  height:100%;
  background: #FFFFFF;
  overflow: hidden;
  .el-table th>.cell{
    text-align: center;
  }
}
body,html{
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 16px;
}
img{
  border-style: none;
    width: 100%;
    height: auto;
  display: block;
}
.flex{
  display: -webkit-flex; /* Safari */
  display: flex;
}
.filter{
  width: 100%;
  padding: 5px 0 0 10px;
  height:100px;
  box-sizing: border-box;
  border-bottom:1px solid #DDD;
  .inp{
    width: 70%;
    margin:0 20px;
  }
  .sel{
    width: 130px;
  }
}
.client{
  display: -webkit-flex; /* Safari */
  display: flex;
  height: 100%;
  .client-left{
    width:400px;
    height: 100%;
    border-right: 1px solid #DDD;
  }
  .client-right{
    width:calc(100% - 400px);
    height:100%;
   /* border: 1px solid green;*/
    overflow: hidden;
    .tag{
      /*border: 1px solid red;*/
      padding: 8px 20px 0 20px;
    }
  }
  .client-ul{
    height:calc(100% - 125px);

  }

  .client-list-two{
    font-size: 25px;
    height: 40px;
    width: 280px;
    text-align: center;
    .state{
      background: #409EFF;
      color: #FFFFFF;
    }
    .stateGreen{
      background: #7FFFAA;
      color: #696969;
    }
    .stateRed{
      background: #FF0000;
      color: #ffffff;
    }
    .stateMaroon{
      background: #800000;
      color: #ffffff;
    }
    .stateDarkRed{
      background: #DC143C;
      color: #696969;
    }
    .stateDarkgray{
      background: #C0C0C0;
      color: #696969;
    }
    .stateYellow{
      background: #F0E68C;
      color: #696969;
    }
    .lightSkyBlue{
      background: #87CEFA;
      color: #696969;
    }

  }
  .client-list{
    cursor: pointer;
    border-bottom: 1px solid #DDD;
    padding:10px 0 0 10px;
    position: relative;
    overflow: hidden;
    height:86px;
    box-sizing: border-box;
    .number{
      height: 20px;
    }
    p{
      margin:0 0 8px 0;
      font-weight: normal;
      font-size: 14px;
    }
    .state{
      background: #409EFF;
      color: #FFFFFF;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }

    }
    .stateGreen{
      background: #7FFFAA;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateRed{
      background: #FF0000;
      color: #ffffff;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateMaroon{
           background: #800000;
           color: #ffffff;
           height: 50px;
           width: 100px;
           position: absolute;
           right: -32px;
           top: -10px;
           transform:rotate(7deg);
           -ms-transform:rotate(7deg); 	/* IE 9 */
           -moz-transform:rotate(7deg); 	/* Firefox */
           -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
           -o-transform:rotate(7deg); 	/* Opera */
           text-align: center;
           span{
             line-height: 80px;
             font-size: 14px;
             /*position: absolute;*/
             /*bottom: 0;*/
           }
         }
    .stateDarkRed{
      background: #DC143C;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateDarkgray{
       background: #C0C0C0;
       color: #696969;
       height: 50px;
       width: 100px;
       position: absolute;
       right: -32px;
       top: -10px;
       transform:rotate(7deg);
       -ms-transform:rotate(7deg); 	/* IE 9 */
       -moz-transform:rotate(7deg); 	/* Firefox */
       -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
       -o-transform:rotate(7deg); 	/* Opera */
       text-align: center;
       span{
         line-height: 80px;
         font-size: 14px;
         /*position: absolute;*/
         /*bottom: 0;*/
       }
     }
    .stateYellow{
      background: #F0E68C;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .lightSkyBlue{
      background: #87CEFA;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .num{
      color: #409EFF;
    }
  }
  .client-list-three{
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height:70px;
    box-sizing: border-box;
    border-bottom: 1px solid #DDD;

    p{
      font-size: 20px;
      text-align: center;
    }
    .state{
      background: #409EFF;
      color: #FFFFFF;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateGreen{
      background: #7FFFAA;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateRed{
      background: #FF0000;
      color: #ffffff;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateMaroon{
      background: #800000;
      color: #ffffff;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateDarkRed{
      background: #DC143C;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateDarkgray{
      background: #C0C0C0;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .stateYellow{
      background: #F0E68C;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }
    .lightSkyBlue{
      background: #87CEFA;
      color: #696969;
      height: 50px;
      width: 100px;
      position: absolute;
      right: -32px;
      top: -10px;
      transform:rotate(7deg);
      -ms-transform:rotate(7deg); 	/* IE 9 */
      -moz-transform:rotate(7deg); 	/* Firefox */
      -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
      -o-transform:rotate(7deg); 	/* Opera */
      text-align: center;
      span{
        line-height: 80px;
        font-size: 14px;
        /*position: absolute;*/
        /*bottom: 0;*/
      }
    }

  }
  }
.opera{
  margin-left:10px;
}
.active-class{
  background: #C4E1FF;
}
/********el-input 样式重置****************/
.line .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius:0px;
  border: none;
  border-bottom: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}
/********elementui el-scroll******/
.default-scrollbar {
  width: 100%;
  height: 100%;
}
.el-scrollbar__wrap.default-scrollbar__wrap {
  overflow-x: auto;
}
.el-scrollbar__view.default-scrollbar__view {}
.el-scrollbar__view.p20-scrollbar__view {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
/*img file*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.colorGreen{
  color: #008000;
}
.colorRed{
  color: #FF1493;
}
.colorBlack{
  color: #000000;
}
</style>
