<template>
  <div v-loading="load">
    <div class="bu-box">
      <el-button type="primary" @click="buAdd">新增人员</el-button>
    </div>
    <el-table :data="crewData"
              border
              stripe
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center'}"
              max-height="700"
    >
      <el-table-column prop="username" label="账号"></el-table-column>
      <el-table-column prop="password" label="密码" ></el-table-column>
      <el-table-column prop="roles" label="角色"></el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="gender" label="性别"></el-table-column>
      <el-table-column prop="moblle" label="手机号码" ></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="operation" label="操作" width="240">
        <template slot-scope="scope">
          <el-button type="warning" size="small" @click="buChan(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="userDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="addChanDialog" width="500px">
      <el-form :model="addChanForm" :rules="rules" ref="addChanForm" label-width="100px">
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="addChanForm.roleId" multiple placeholder="请选择角色" class="input-class">
            <el-option
                v-for="item in roleData"
                :key="item.roleId.toString()"
                :label="item.roleName"
                :value="item.roleId.toString()">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" v-if="isAdd" prop="username">
          <el-input v-model="addChanForm.username" class="input-class" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addChanForm.password" class="input-class" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="deleteStatus">
          <el-select v-model="addChanForm.deleteStatus"  placeholder="请选择状态" class="input-class">
            <el-option
                v-for="item in userStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="addChanForm.gender">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="手机号码">
          <el-input v-model="addChanForm.moblle" class="input-class" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="addChanForm.nickname" class="input-class" placeholder="请输入昵称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addChanSubmit('addChanForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  backstageUserList,
  addBackstageUser,
  updateBackstageUser,
  listRole,
  stopBackstageUser,
  deleteBackstageUser,
  userActive,
  dictionaryUserStatus
} from "@/common/js/index.js"
import {isEmpty} from "../common/js/util";

export default {
  name: "crew",
  data() {
    return {
      siteList: [],
      load: true,
      title: "新增人员",
      crewData: [],
      roleData: [],
      roles:'',
      addChanDialog: false,
      addChanForm: {
        //deleteStatus: null,
        gender: "",
        moblle: "",
        nickname: "",
        password: "",
        //permissionList: [],
        roleId: [],
        userId: null,
        username: "",
        deleteStatus:null,
      },
      isAdd: false,
      rules: {
        roleId: [{required: true, message: '请选择角色', trigger: 'blur'}],
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        deleteStatus: [{required: true, message: '请选择状态', trigger: 'blur'}],
      },
      userStatus:[],
    }
  },
  methods: {

    getUserList() {
      backstageUserList().then(res => {
        if (res) {
          this.load = false;
          this.crewData = res.data;
          for (let i = 0; i < this.crewData.length; i++) {
            this.crewData[i].permissionList = this.crewData[i].permissionList.join('，');
            for (let x = 0; x < this.userStatus.length; x++) {
              if (this.userStatus[x].id === this.crewData[i].deleteStatus){
                this.crewData[i].status = this.userStatus[x].name
              }
            }

            if (isEmpty(this.crewData[i].roleId)) {
              this.crewData[i].roleId = this.crewData[i].roleId.split(",");
            } else {
              this.crewData[i].roleId = []
            }

          }
        }
      })
    },
    buAdd() {
      this.addChanDialog = true;
      this.title = "新增人员";
      this.isAdd = true;
      this.addChanForm = {
        //deleteStatus: null,
        gender: "",
        moblle: "",
        nickname: "",
        password: "",
        //permissionList: [],
        roleId: [],
        userId: null,
        username: "",
      };
    },
    buChan(row) {
      this.isAdd = false;
      this.addChanDialog = true;
      this.title = "修改人员信息";
      this.addChanForm.nickname = row.nickname,
      this.addChanForm.password = row.password,
      this.addChanForm.gender = row.gender,
      this.addChanForm.moblle = row.moblle,
      this.addChanForm.roleId = row.roleId,
      this.addChanForm.userId = row.userId,
      this.addChanForm.deleteStatus = row.deleteStatus
    },
    addChanSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let addData = {
            username: this.addChanForm.username,
            password: this.addChanForm.password,
            nickname: this.addChanForm.nickname,
            roleId: this.addChanForm.roleId.join(","),
            gender: this.addChanForm.gender,
            moblle: this.addChanForm.moblle,
            deleteStatus: this.addChanForm.deleteStatus
          };
          let chanData = {
            userId: this.addChanForm.userId,
            password: this.addChanForm.password,
            nickname: this.addChanForm.nickname,
            roleId: this.addChanForm.roleId.join(","),
            gender: this.addChanForm.gender,
            moblle: this.addChanForm.moblle,
            deleteStatus: this.addChanForm.deleteStatus
          };
          if (this.title == "新增人员") {
            addBackstageUser(addData).then(res => {
              if (res) {
                this.addChanDialog = false;
                this.getUserList();
                this.$message.success('添加人员成功');
              }
            })
          } else if (this.title == "修改人员信息") {
            updateBackstageUser(chanData).then(res => {
              if (res) {
                this.addChanDialog = false;
                this.getUserList();
                this.$message.success('修改人员信息成功');
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    /**
     * 激活人员
     * */
    userActive(row) {
      this.$confirm('此操作将激活该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        userActive(subData).then(res => {
          if (res) {
            this.$message.success('激活成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消激活'
        });
      });
    },
    /**
     * 删除管理员
     * */
    userDelete(row) {
      this.$confirm('此操作将永久删除该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        deleteBackstageUser(subData).then(res => {
          if (res) {
            this.$message.success('删除成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /**
     *停止人员
     * */
    stopBackstageUser(row) {
      this.$confirm('此操作停止该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        stopBackstageUser(subData).then(res => {
          if (res) {
            this.$message.success('停止人员成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消停止人员'
        });
      });
    },
    getRoleList() {
      listRole().then(res => {
        if (res) {
          this.roleData = res.data.list;
        }
      })
    },
    dictionaryUserStatus() {
      dictionaryUserStatus().then(res => {
        if (res) {
          this.userStatus = res.data
        }
      })
    },
  },
  created() {
    this.dictionaryUserStatus();
    this.getUserList();
    this.getRoleList();
  }
}
</script>
<style scoped>
.bu-box {
  text-align: right;
  padding: 20px;

}

.input-class {
  width: 200px;
}
</style>
