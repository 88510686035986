import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/login.vue';
import Dictionary from '@/views/dictionary.vue';
import UserInfo from '@/views/userInfo.vue';
import TopicNews from '@/views/topicNews.vue';
import PointsRecord from '@/views/pointsRecord.vue';
import OrderRecord from '@/views/orderRecord.vue';
import ActivityInformation from '@/views/activityInformation.vue';
import ActivityProcedure from '@/views/activityProcedure.vue';
import ActivityRegistrationSigninRecord from '@/views/activityRegistrationSigninRecord.vue';
import PointsChange from '@/views/pointsChange.vue';
import Role from '@/views/role.vue';
import Log from '@/views/log.vue';
import Crew from '@/views/crew.vue';
import Livebroadcast from '@/views/livebroadcast.vue';
Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/login'
        },

        {
            path: '/home',
            name: 'home',
            component: Home,
            redirect: '/userInfo',
            children:[
                {
                    path: '/dictionary',
                    name: 'dictionary',
                    component:Dictionary,
                },
                {
                    path: '/userInfo',
                    name: 'userInfo',
                    component:UserInfo,
                },
                {
                    path: '/topicNews',
                    name: 'topicNews',
                    component:TopicNews,
                },
                {
                    path: '/pointsRecord',
                    name: 'pointsRecord',
                    component:PointsRecord,
                },
                {
                    path: '/orderRecord',
                    name: 'orderRecord',
                    component:OrderRecord,
                },
                {
                    path: '/activityInformation',
                    name: 'activityInformation',
                    component:ActivityInformation,
                },
                {
                    path: '/activityProcedure',
                    name: 'activityProcedure',
                    component:ActivityProcedure,
                },
                {
                    path: '/activityRegistrationSigninRecord',
                    name: 'activityRegistrationSigninRecord',
                    component:ActivityRegistrationSigninRecord,
                },
                {
                    path: '/pointsChange',
                    name: 'pointsChange',
                    component:PointsChange,
                },
                {
                    path: '/log',
                    name: 'log',
                    component:Log,
                },
                {
                    path: '/role',
                    name: 'role',
                    component:Role,
                },
                {
                    path: '/crew',
                    name: 'crew',
                    component:Crew,
                },
                {
                    path: '/livebroadcast',
                    name: 'livebroadcast',
                    component:Livebroadcast,
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
    ]
})
