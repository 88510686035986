<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">
        <el-form-item>
          <el-input v-model="searchForm" placeholder="请输入姓名、微信号、微信手机号、真实姓名" style="width:400px"
                    @keyup.enter.native="topicNews"
                    clearable></el-input>
          <el-button type="primary" @click="topicNews()">搜索</el-button>
          <el-button type="success" @click="addPageButton()">新增</el-button>
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>

        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="news"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="主题"
        >
        </el-table-column>
        <el-table-column
            prop="details"
            label="详情"
        >
        </el-table-column>
        <el-table-column prop="img" label="图片" width="120">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.img" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="priority"
            label="优先级"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="updateButton(scope.row.id)">修改</el-button>
            <el-button type="danger" @click="deleteTopicNews(scope.row.id,1)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="主题 : " label-width="100px"  prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详情 : " label-width="100px" prop="details">
                <el-input v-model="ruleForm.details"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优先级 : " label-width="100px" prop="priority">
                <el-input v-model="ruleForm.priority"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="链接 : " label-width="100px">
                <el-input v-model="ruleForm.link"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态 : " label-width="100px" prop="statusId">
                <el-select v-model="ruleForm.statusId" clearable placeholder="状态">
                  <el-option
                      v-for="item in topicStatus"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <h4 style="color: #00a7b8">图片推荐尺寸:宽415px:高311px 、比例4:3</h4>
          <el-row>
            <el-col :span="4">
              <h2>主题图片</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>


        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="主题 : " label-width="100px"  prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详情 : " label-width="100px">
                <el-input v-model="ruleForm.details"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优先级 : " label-width="100px" prop="priority">
                <el-input v-model="ruleForm.priority"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="链接 : " label-width="100px">
                <el-input v-model="ruleForm.link"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="状态 : " label-width="100px">
                <el-select v-model="ruleForm.statusId" clearable placeholder="状态">
                  <el-option
                      v-for="item in topicStatus"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>


          </el-row>
          <h4 style="color: #00a7b8">图片推荐尺寸:宽415px:高311px 、比例4:3</h4>
          <el-row>
            <el-col :span="4">
              <h2>主题图片</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>


        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updateTopicNews()">修改主题新闻</el-button>
        </div>
      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>主题新闻页</h2>
          *本页用于对主题新闻的信息维护，主要专注于活动后的，过程记录。
          <br>
          <h3> 搜索：在上方搜索栏中，模糊输入相关信息后可以进行数据搜索和筛选。<br>
            新增：在上方搜索栏旁有“新增”按钮，点击后进入新增页面。<br>
            修改：在每条记录右侧有“修改”按钮，点击后进入修改页面。<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  topicNews, topicStatus, addTopicNews, deleteTopicNews, updateTopicNews
} from "@/common/js/index.js"
import {isEmpty, setTime} from "@/common/js/util";

export default {
  name: "topicNews",
  data() {
    return {
      guidePage:false,
      idBacUrl:'',
      news: [],
      topicStatus: [],
      searchForm: '',
      all: 0,
      currentPage: 1,
      loading: false,
      addPage: false,
      updatePage: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        name: null,
        details: null,
        img: null,
        priority: null,
        statusId: null,
        link: null,
      },
      rules: {
        name: [{required: true,message: '请填写主题名', trigger: 'blur'}],
        priority: [{required: true,message: '请填写优先级',  trigger: 'blur'}],
        statusId: [{required: true, message: '请选择状态',  trigger: 'blur'}],
      },
    }
  },
  methods: {
    onUploadChange(file) {
      this.idBacUrl = URL.createObjectURL(file.raw);
    },
    updateButton(id){
      this.updatePage = true;
      for (let i = 0; i < this.news.length; i++) {
        if (id === this.news[i].id){
          this.ruleForm = this.news[i]
          this.idBacUrl = this.ruleForm.img
        }
      }
    },
    updateTopicNews(){
      let  subData = this.getFormData();
      updateTopicNews(subData).then(res => {
        if (res) {
          this.$message.success('修改成功');
          this.topicNews()
          this.updatePage = false
        }

      })
    },
    deleteTopicNews(id){
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deleteTopicNews(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.topicNews()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton(){
      this.addPage = true
      this.ruleForm = {
        name: null,
        details: null,
        img: null,
        priority: null,
        statusId: null,
        link: null,
      }
      this.idBacUrl = ""
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.name) && subData.append("name", this.ruleForm.name);
      isEmpty(this.ruleForm.details) && subData.append("details", this.ruleForm.details);
      isEmpty(this.ruleForm.priority) && subData.append("priority", this.ruleForm.priority);
      isEmpty(this.ruleForm.statusId) && subData.append("statusId", this.ruleForm.statusId);
      isEmpty(this.ruleForm.link) && subData.append("link", this.ruleForm.link);
      let imgUpload = this.$refs.imgUpload.uploadFiles;
      if (imgUpload !== null && imgUpload.length !== 0) {
        subData.append("topicNewsImg", imgUpload[imgUpload.length - 1].raw);
      }else {
        subData.append("img", '');
      }
      return subData;
    },
    add(ruleForm){
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let  subData = this.getFormData();
          addTopicNews(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.topicNews()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    topicNews() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
      topicNews(subData).then(res => {
        if (res) {
          this.news = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    selectTopicStatus() {
      topicStatus().then(res => {
        if (res) {
          this.topicStatus = res.data
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.topicNews();
    this.selectTopicStatus();
  },
  watch: {
    currentPage: function () {
      this.topicNews()
    },
    pagesize: function () {
      this.topicNews()
    },
  }
}
</script>

<style scoped>
/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
