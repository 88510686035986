<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">
        <el-form-item>
          <el-input v-model="searchForm" placeholder="请输入姓名、微信号、微信手机号、真实姓名" style="width:400px"
                    @keyup.enter.native="queryUser"
                    clearable></el-input>
          <el-button type="primary" @click="queryUser()">搜索</el-button>
          <el-button type="success" @click="addPageButton()">新增</el-button>
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="userInfo"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="id"
            label="ID"
            width="60px"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="120px"
        >
        </el-table-column>
        <el-table-column
            prop="wechatPhone"
            label="微信手机号"
            width="120px"
        >
        </el-table-column>
        <el-table-column
            prop="nickName"
            label="昵称"
        >
        </el-table-column>
        <el-table-column
            prop="sex"
            label="性别"
            width="60px"
        >
        </el-table-column>
        <el-table-column prop="operation" label="会员等级" width="125px">
          <template slot-scope="scope">
            <el-select v-model="scope.row.membershipLevelId" disabled style="width: 110px;">
              <el-option
                  v-for="item in membershipLevel"
                  :key="item.id"
                  :label="item.level"
                  :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="行业领域" width="300px">
          <template slot-scope="scope">
            <el-select v-model="scope.row.industrySectorIds" multiple disabled style="width: 100%;">
              <el-option
                  v-for="item in industrySector"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>


        <el-table-column
            prop="affiliatedCompany"
            label="所属企业"
        >
        </el-table-column>
        <el-table-column
            prop="pointsStatistics"
            label="积分统计"
        >
        </el-table-column>
        <el-table-column
            prop="pointsStatisticsTime"
            label="积分更新时间"
            width="180px"
        >
        </el-table-column>
        <el-table-column
            prop="duePoints"
            label="到期积分"
        >
        </el-table-column>
        <el-table-column
            prop="pointsExpirationTime"
            label="到期时间"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="280px">
          <template slot-scope="scope">
            <el-button type="info" @click="detailedButton(scope.row.id)">详情</el-button>
            <el-button type="primary" @click="updateButton(scope.row.id)">修改</el-button>
            <el-button type="danger" @click="deleteUser(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="详情" :visible.sync="detailedPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名 : " label-width="120px" prop="name">
                <el-input v-model="ruleForm.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号 : " label-width="120px" prop="wechat">
                <el-input v-model="ruleForm.wechat" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信手机号 : " label-width="120px" prop="wechatPhone">
                <el-input v-model="ruleForm.wechatPhone" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="昵称 : " label-width="120px" prop="nickName">
                <el-input v-model="ruleForm.nickName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属企业 : " label-width="120px">
                <el-input v-model="ruleForm.affiliatedCompany" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="性别 : " label-width="120px" prop="sex">
                <el-input v-model="ruleForm.sex" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="真实姓名 : " label-width="120px">
                <el-input v-model="ruleForm.actualName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄 : " label-width="120px" prop="age">
                <el-input v-model="ruleForm.age" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="行业领域 : " label-width="120px">
                <el-select v-model="ruleForm.industrySectorId" multiple disabled placeholder="行业领域"
                           style="width: 100%;">
                  <el-option
                      v-for="item in industrySector"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      disabled>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级 : " label-width="120px" prop="membershipLevelId">
                <el-select v-model="ruleForm.membershipLevelId" clearable placeholder="会员等级" disabled>
                  <el-option
                      v-for="item in membershipLevel"
                      :key="item.id"
                      :label="item.level"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="签到统计 : " label-width="120px">
                <el-input v-model="ruleForm.signinStatistics" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="积分统计 : " label-width="120px">
                <el-input v-model="ruleForm.pointsStatistics" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="积分更新时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.pointsStatisticsTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    disabled>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="简介 : " label-width="120px">
                <el-input v-model="ruleForm.synopsis" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="到期积分 : " label-width="120px">
                <el-input v-model="ruleForm.duePoints" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="到期时间 : " label-width="120px">
                <el-input v-model="ruleForm.pointsExpirationTime" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <h2>用户头像</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
                  disabled
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>

        </el-form>

      </el-dialog>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <!--        <el-form :model="ruleForm" ref="ruleForm" >-->
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名 : " label-width="120px" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号 : " label-width="120px" prop="wechat">
                <el-input v-model="ruleForm.wechat"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信手机号 : " label-width="120px" prop="wechatPhone">
                <el-input v-model="ruleForm.wechatPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="昵称 : " label-width="120px" prop="nickName">
                <el-input v-model="ruleForm.nickName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属企业 : " label-width="120px">
                <el-input v-model="ruleForm.affiliatedCompany"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="性别 : " label-width="120px" prop="sex">
                <el-input v-model="ruleForm.sex"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="真实姓名 : " label-width="120px">
                <el-input v-model="ruleForm.actualName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄 : " label-width="120px" prop="age">
                <el-input v-model="ruleForm.age"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="行业领域 : " label-width="120px">
                <el-select v-model="ruleForm.industrySectorId" multiple clearable placeholder="行业领域"
                           style="width: 100%;">
                  <el-option
                      v-for="item in industrySector"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级 : " label-width="120px" prop="membershipLevelId">
                <el-select v-model="ruleForm.membershipLevelId" clearable placeholder="行业领域">
                  <el-option
                      v-for="item in membershipLevel"
                      :key="item.id"
                      :label="item.level"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="签到统计 : " label-width="120px">
                <el-input v-model="ruleForm.signinStatistics" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="积分统计 : " label-width="120px">
                <el-input v-model="ruleForm.pointsStatistics" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="积分更新时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.pointsStatisticsTime"
                    type="datetime"
                    placeholder="选择日期时间" disabled>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="简介 : " label-width="120px">
                <el-input v-model="ruleForm.synopsis"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <h2>用户头像</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>


        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="addUser('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <h2>用户ID：{{ ruleForm.id }}</h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名 : " label-width="120px" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号 : " label-width="120px" prop="wechat">
                <el-input v-model="ruleForm.wechat"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信手机号 : " label-width="120px" prop="wechatPhone">
                <el-input v-model="ruleForm.wechatPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="昵称 : " label-width="120px" prop="nickName">
                <el-input v-model="ruleForm.nickName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属企业 : " label-width="120px">
                <el-input v-model="ruleForm.affiliatedCompany"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="性别 : " label-width="120px" prop="sex">
                <el-input v-model="ruleForm.sex"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="真实姓名 : " label-width="120px">
                <el-input v-model="ruleForm.actualName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄 : " label-width="120px" prop="age">
                <el-input v-model="ruleForm.age"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="行业领域 : " label-width="120px">
                <el-select v-model="ruleForm.industrySectorId" multiple clearable placeholder="行业领域"
                           style="width: 100%;">
                  <el-option
                      v-for="item in industrySector"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员等级 : " label-width="120px" prop="membershipLevelId">
                <el-select v-model="ruleForm.membershipLevelId" clearable placeholder="行业领域">
                  <el-option
                      v-for="item in membershipLevel"
                      :key="item.id"
                      :label="item.level"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="签到统计 : " label-width="120px">
                <el-input v-model="ruleForm.signinStatistics"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="积分统计 : " label-width="120px">
                <el-input v-model="ruleForm.pointsStatistics"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="12">
              <el-form-item label="积分更新时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.pointsStatisticsTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="简介 : " label-width="120px">
                <el-input v-model="ruleForm.synopsis"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="到期积分 : " label-width="120px">
                <el-input v-model="ruleForm.duePoints"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="到期时间 : " label-width="120px">
                <el-date-picker
                    v-model="ruleForm.pointsExpirationTime"
                    type="date"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="4">
              <h2>用户头像</h2>
            </el-col>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  ref="imgUpload"
                  accept="image/jpeg,image/gif,image/png"
                  :on-change="onUploadChange"
              >
                <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updateUser()">修改用户信息</el-button>
        </div>
      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>1.用户信息页</h2>
          *本页用于对用户进行信息维护。
          <br>
          <h3> 搜索：在上方搜索栏中，模糊输入姓名、或微信号、或微信手机号、或真实姓名，可以进行数据搜索和筛选。<br>
            新增：在上方搜索栏旁有“新增”按钮，点击后进入新增页面<br>
            详情：在每条记录右侧有“详情”按钮，点击后进入用户详情查看页面，详情查看页面中不能对用户进行数据修改。<br>
            修改：在每条记录右侧有“修改”按钮，点击后进入用户详情修改页面<br>
            删除：在每条记录右侧有“删除”按钮，点击后进入用户详情删除页面，点击后会弹出确认窗口，按“确认”完成删除。<br></h3>
          <h2>2.用户新增和修改页</h2>
          *本页用于对用户进行信息维护。
          <br>
          <h3> 头像：用户头像取自微信，不做更改<br>
            必填属性：有红色*的为必填属性<br>
          </h3>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  queryUser,
  addUser,
  industrySector,
  membershipLevel,
  deleteUser,
  modifyUser
} from "@/common/js/index.js"
import {setTime, setDate, isEmpty} from "@/common/js/util.js";

export default {
  name: "userInfo",
  data() {
    return {
      idBacUrl: "",
      userInfo: [],
      membershipLevel: [],
      industrySector: [{
        id: '',
        name: ''
      }],
      searchForm: '',
      addPage: false,
      updatePage: false,
      detailedPage: false,
      all: 0,
      currentPage: 1,
      loading: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        id: null,
        name: null,
        wechat: null,
        wechatPhone: null,
        wechatAvatar: null,
        nickName: null,
        synopsis: null,
        membershipLevelId: null,
        sex: null,
        actualName: null,
        age: null,
        industrySectorId: [],
        industrySectorIds: [],
        homepage: null,
        signinStatistics: null,
        pointsStatistics: null,
        pointsStatisticsTime: null,
        affiliatedCompany: null,
        duePoints:null,
        pointsExpirationTime:null,
      },
      rules: {
        name: [{required: true, message: '请填写姓名', trigger: 'blur'}],
        wechat: [{required: true, message: '请填写微信号', trigger: 'blur'}],
        wechatPhone: [{required: true, message: '请填写微信手机号', trigger: 'blur'}],
        nickName: [{required: true, message: '请填写昵称', trigger: 'blur'}],
        sex: [{required: true, message: '请填写性别', trigger: 'blur'}],
        age: [{required: true, message: '请填写年龄', trigger: 'blur'}],
        membershipLevelId: [{required: true, message: '请选择会员等级', trigger: 'blur'}],
      },
      guidePage: false,
    }
  },
  methods: {
    onUploadChange(file) {
      this.idBacUrl = URL.createObjectURL(file.raw);
    },
    detailedButton(id) {
      this.detailedPage = true;
      for (let i = 0; i < this.userInfo.length; i++) {
        if (id === this.userInfo[i].id) {
          this.ruleForm = this.userInfo[i]
          this.idBacUrl = this.ruleForm.wechatAvatar
          this.ruleForm.industrySectorId = this.ruleForm.industrySectorIds
        }
      }
    },
    updateButton(id) {
      this.updatePage = true;
      for (let i = 0; i < this.userInfo.length; i++) {
        if (id === this.userInfo[i].id) {
          this.ruleForm = this.userInfo[i]
          this.idBacUrl = this.ruleForm.wechatAvatar
          this.ruleForm.industrySectorId = this.ruleForm.industrySectorIds
        }
      }
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.name) && subData.append("name", this.ruleForm.name);
      isEmpty(this.ruleForm.wechat) && subData.append("wechat", this.ruleForm.wechat);
      isEmpty(this.ruleForm.wechatPhone) && subData.append("wechatPhone", this.ruleForm.wechatPhone);
      isEmpty(this.ruleForm.nickName) && subData.append("nickName", this.ruleForm.nickName);
      isEmpty(this.ruleForm.synopsis) && subData.append("synopsis", this.ruleForm.synopsis);
      isEmpty(this.ruleForm.membershipLevelId) && subData.append("membershipLevelId", this.ruleForm.membershipLevelId);
      isEmpty(this.ruleForm.sex) && subData.append("sex", this.ruleForm.sex);
      isEmpty(this.ruleForm.actualName) && subData.append("actualName", this.ruleForm.actualName);
      isEmpty(this.ruleForm.age) && subData.append("age", this.ruleForm.age);
      isEmpty(this.ruleForm.industrySectorId) && subData.append("industrySectorId", this.ruleForm.industrySectorId);
      isEmpty(this.ruleForm.homepage) && subData.append("homepage", this.ruleForm.homepage);
      isEmpty(this.ruleForm.signinStatistics) && subData.append("signinStatistics", this.ruleForm.signinStatistics);
      isEmpty(this.ruleForm.pointsStatistics) && subData.append("pointsStatistics", this.ruleForm.pointsStatistics);
      isEmpty(this.ruleForm.pointsStatisticsTime) && subData.append("addPointsStatisticsTime", setTime(this.ruleForm.pointsStatisticsTime));
      isEmpty(this.ruleForm.pointsExpirationTime) && subData.append("pointsExpirationTime", setDate(this.ruleForm.pointsExpirationTime));
      isEmpty(this.ruleForm.duePoints) && subData.append("duePoints", this.ruleForm.duePoints);
      isEmpty(this.ruleForm.affiliatedCompany) && subData.append("affiliatedCompany", this.ruleForm.affiliatedCompany);
      let imgUpload = this.$refs.imgUpload.uploadFiles;
      if (imgUpload !== null && imgUpload.length !== 0) {
        subData.append("img", imgUpload[imgUpload.length - 1].raw);
      }
      return subData;
    },
    updateUser() {
      let subData = this.getFormData();
      modifyUser(subData).then(res => {
        if (res) {
          this.$message.success('修改成功');
          this.queryUser()
          this.updatePage = false
        }

      })
    },
    deleteUser(id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deleteUser(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.queryUser()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addUser(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData();
          addUser(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.queryUser()
              this.addPage = false

            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },
    addPageButton() {
      this.addPage = true
      this.ruleForm = {}
      this.idBacUrl = ""
    },
    queryUser() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
      queryUser(subData).then(res => {
        if (res) {
          this.userInfo = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    selectIndustrySector() {
      industrySector().then(res => {
        if (res) {
          this.industrySector = res.data
        }
      })
    },
    selectMembershipLevel() {
      membershipLevel().then(res => {
        if (res) {
          this.membershipLevel = res.data
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.queryUser();
    this.selectIndustrySector();
    this.selectMembershipLevel();
  },
  watch: {
    currentPage: function () {
      this.queryUser()
    },
    pagesize: function () {
      this.queryUser()
    },
  }
}
</script>

<style scoped>
/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
