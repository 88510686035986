<template>
  <div class="site">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left" style="text-align: right;margin-right: 100px">
        <el-form :inline="true" :model="addChanForm" class="demo-form-inline" style="z-index:2">
          <el-form-item label="角色" prop="roleId">
            <el-select v-model="addChanForm.roleId" placeholder="请选择角色" class="input-class" @change="getRoleCompetenceList()">
              <el-option
                  v-for="item in roleData"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="success" @click="dialogFormVisible = true">新增角色</el-button>
          <el-button type="danger" @click="deleteSelected()">删除角色</el-button>
        </el-form>
      </div>

    </div>
    <el-table
        :data="roleCompetenceList"
        border
        v-loading="loading"
        stripe
        :header-cell-style="{background:'#dee2ec', color: '#666666'}"
        max-height="750"
        style="width: 100%">

      <el-table-column
          type="index"
          label="序号"
          width="50">
      </el-table-column>

      <el-table-column
          width="150"
          prop="menu_name"
          label="模块"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-checkbox-group v-model="row.ids">
            <span
                v-for="(item, index) in row.permissionsList"
                :key="index"
                style="margin-right: 10px"
            >
                <el-checkbox :label="item.id" :key="item.id" >{{ item.permissionName }}</el-checkbox>
                </span>
          </el-checkbox-group>

        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="warning" size="small" @click="modify(scope.row)">修改</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-dialog :title="title" :visible.sync="modifyDialog" width="500px">
      <el-form :model="addModifyForm" ref="addModifyForm" label-width="100px">

        <el-form-item label="权限列表">
          <el-select v-model="addModifyForm.menuCodeIds" multiple placeholder="请选择" clearable filterable>
            <el-option
                v-for="item in permissionsList"
                :key="item.id"
                :label="item.permissionName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDialog = false">取 消</el-button>
        <el-button type="primary" @click="modifyRolePermission('addModifyForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="dialogFormVisible">
      <el-form :model="addChanForm" ref="subAddForm">
        <el-form-item label="角色" clearable>
          <el-input v-model="addChanForm.roleName" placeholder="请输入角色名字" style="width:28.5%"></el-input>
        </el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRoleInfo()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAllPermission,
  queryRoleCompetenceList,
  modifyRolePermission,
  listRole,
  addRoleInfo,
  deleteRole
} from '@/common/js/index.js'

export default {
  name: "role",
  data() {
    return {
      loading: true,
      roleCompetenceList: [],
      siteId: null,
      title: "权限",
      modifyDialog: false,
      addModifyForm: {
        menuCodeIds: [],
        menu_code:''
      },
      permissionsList: [],//权限列表
      checkList:["护理员信息"],
      roleData: [{
        roleId:''
      }],
      addChanForm: {
        roleId: 0,
        roleName:''
      },
      dialogFormVisible:false,
    }
  },
  methods: {
    deleteSelected() {   //删除角色
      let subData = {roleId: this.addChanForm.roleId}
      this.$confirm('此操作将永久删除'+this.addChanForm.roleName+'角色，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole(subData).then(res => {
          if (res) {
            this.$message.success('删除成功')
            this.getRoleList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    addRoleInfo(){
      let subData = 'roleName=' + this.addChanForm.roleName
      addRoleInfo(subData).then(res => {
        if (res) {
          this.getRoleList()
          this.dialogFormVisible = false
          this.$message.success('角色新增成功')
        }
      })
    },
    getRoleList() {
      listRole().then(res => {
        if (res) {
          this.roleData = res.data.list;
          if (this.roleData.length!==0){
            this.addChanForm.roleId = this.roleData[this.roleData.length-1].roleId
            this.getRoleCompetenceList()
          }
        }
      })
    },

    modify(row) {
      this.title = "修改" + row.menu_name + "权限"
      this.permissionsList = row.permissionsList
      this.addModifyForm.menuCodeIds  = row.ids
      this.addModifyForm.menu_code = row.menu_code
      this.modifyDialog = true;
    },
    modifyRolePermission(){
      let subData = 'roleId=' + this.addChanForm.roleId
          + '&menuCodeIds=' + this.addModifyForm.menuCodeIds
          + '&menu_code=' + this.addModifyForm.menu_code

      modifyRolePermission(subData).then(res => {
        if (res) {
          this.getRoleCompetenceList()
          this.modifyDialog = false
          this.$message.success('修改成功')
        }
      })
    },
    getRoleCompetenceList() {
      let subData = 'roleId=' + this.addChanForm.roleId
      queryRoleCompetenceList(subData).then(res => {
        this.roleCompetenceList = res.data
        for (let i = 0; i < this.roleCompetenceList.length; i++) {
          const ids = []
          for (let v = 0; v < this.roleCompetenceList[i].permissions.length; v++) {
            ids.push(this.roleCompetenceList[i].permissions[v].id)
          }
          this.roleCompetenceList[i].ids = ids

          for (let j = 0; j < this.permissionList.length; j++) {
            if (this.roleCompetenceList[i].menu_code === this.permissionList[j].menuCode) {
              this.roleCompetenceList[i].permissionsList = this.permissionList[j].permissions
            }
          }

        }
        this.loading = false
      })

    },
    getAllPermission() {//获取所有权限
      listAllPermission().then(res => {
        this.permissionList = res.data.list
      })
    },

  },
  mounted() {
    this.getAllPermission()
    this.getRoleList()
  },
  created() {
  },
  watch: {
  }
}
</script>
