import fetch from '@/common/js/http.js'
import { EPROTO } from 'constants';
import axios from "axios";
let prefix;
prefix = '/api';
/**
 * 登录
 * */
//登录
export function login(subData) {
    return fetch({
        url: prefix+'/systemAccount/systemUserLogin',
        method: 'post',
        data:subData
    });
}
// 活动状态字典
export function activeState(subData) {
    return fetch({
        url: prefix+'/dictionary/activeState',
        method: 'post',
        data:subData
    });
}
// 活动类型字典
export function activeType(subData) {
    return fetch({
        url: prefix+'/dictionary/activeType',
        method: 'post',
        data:subData
    });
}
// 行业领域字典
export function industrySector(subData) {
    return fetch({
        url: prefix+'/dictionary/industrySector',
        method: 'post',
        data:subData
    });
}
// 会员等级字典
export function membershipLevel(subData) {
    return fetch({
        url: prefix+'/dictionary/membershipLevel',
        method: 'post',
        data:subData
    });
}
// 订单状态字典
export function orderStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/orderStatus',
        method: 'post',
        data:subData
    });
}
// 积分类型字典
export function pointsType(subData) {
    return fetch({
        url: prefix+'/dictionary/pointsType',
        method: 'post',
        data:subData
    });
}
// 主题状态字典
export function topicStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/topicStatus',
        method: 'post',
        data:subData
    });
}
export function addActiveState(subData) {
    return fetch({
        url: prefix+'/dictionary/addActiveState',
        method: 'post',
        data:subData
    });
}
export function addActiveType(subData) {
    return fetch({
        url: prefix+'/dictionary/addActiveType',
        method: 'post',
        data:subData
    });
}
export function addIndustrySector(subData) {
    return fetch({
        url: prefix+'/dictionary/addIndustrySector',
        method: 'post',
        data:subData
    });
}
export function addMembershipLevel(subData) {
    return fetch({
        url: prefix+'/dictionary/addMembershipLevel',
        method: 'post',
        data:subData
    });
}
export function addOrderStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/addOrderStatus',
        method: 'post',
        data:subData
    });
}
export function addPointsType(subData) {
    return fetch({
        url: prefix+'/dictionary/addPointsType',
        method: 'post',
        data:subData
    });
}
export function addTopicStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/addTopicStatus',
        method: 'post',
        data:subData
    });
}
export function updateActiveState(subData) {
    return fetch({
        url: prefix+'/dictionary/updateActiveState',
        method: 'post',
        data:subData
    });
}
export function updateActiveType(subData) {
    return fetch({
        url: prefix+'/dictionary/updateActiveType',
        method: 'post',
        data:subData
    });
}
export function updateIndustrySector(subData) {
    return fetch({
        url: prefix+'/dictionary/updateIndustrySector',
        method: 'post',
        data:subData
    });
}
export function updateMembershipLevel(subData) {
    return fetch({
        url: prefix+'/dictionary/updateMembershipLevel',
        method: 'post',
        data:subData
    });
}
export function updateOrderStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/updateOrderStatus',
        method: 'post',
        data:subData
    });
}
export function updatePointsType(subData) {
    return fetch({
        url: prefix+'/dictionary/updatePointsType',
        method: 'post',
        data:subData
    });
}
export function updateTopicStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/updateTopicStatus',
        method: 'post',
        data:subData
    });
}
export function deleteActiveState(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteActiveState',
        method: 'post',
        data:subData
    });
}
export function deleteActiveType(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteActiveType',
        method: 'post',
        data:subData
    });
}
export function deleteIndustrySector(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteIndustrySector',
        method: 'post',
        data:subData
    });
}
export function deleteMembershipLevel(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteMembershipLevel',
        method: 'post',
        data:subData
    });
}
export function deleteOrderStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteOrderStatus',
        method: 'post',
        data:subData
    });
}
export function deletePointsType(subData) {
    return fetch({
        url: prefix+'/dictionary/deletePointsType',
        method: 'post',
        data:subData
    });
}
export function deleteTopicStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteTopicStatus',
        method: 'post',
        data:subData
    });
}
export function queryUser(subData) {
    return fetch({
        url: prefix+'/xkyUserInfo/queryUser',
        method: 'post',
        data:subData
    });
}
export function topicNews(subData) {
    return fetch({
        url: prefix+'/xkyTopicNews/topicNews',
        method: 'post',
        data:subData
    });
}
export function queryPointsRecord(subData) {
    return fetch({
        url: prefix+'/xkyPointsRecord/queryPointsRecord',
        method: 'post',
        data:subData
    });
}
export function queryOrderRecord(subData) {
    return fetch({
        url: prefix+'/xkyOrderRecord/queryOrderRecord',
        method: 'post',
        data:subData
    });
}
export function activityQuery(subData) {
    return fetch({
        url: prefix+'/xkyActivityInformation/activityQuery',
        method: 'post',
        data:subData
    });
}
export function inquiryActivityProcedure(subData) {
    return fetch({
        url: prefix+'/xkyActivityProcedure/inquiryActivityProcedure',
        method: 'post',
        data:subData
    });
}
export function inquiryRecord(subData) {
    return fetch({
        url: prefix+'/xkyActivityRegistrationSigninRecord/inquiryRecord',
        method: 'post',
        data:subData
    });
}
export function addUser(subData) {
    return fetch({
        url: prefix+'/xkyUserInfo/addUser',
        method: 'post',
        data:subData
    });
}
export function addPointsRecord(subData) {
    return fetch({
        url: prefix+'/xkyPointsRecord/addPointsRecord',
        method: 'post',
        data:subData
    });
}
export function addOrderRecord(subData) {
    return fetch({
        url: prefix+'/xkyOrderRecord/addOrderRecord',
        method: 'post',
        data:subData
    });
}
export function activityApplication(subData) {
    return fetch({
        url: prefix+'/xkyActivityInformation/activityApplication',
        method: 'post',
        data:subData
    });
}
export function addActivityProcedure(subData) {
    return fetch({
        url: prefix+'/xkyActivityProcedure/addActivityProcedure',
        method: 'post',
        data:subData
    });
}
export function registrationAndCheckInRecord(subData) {
    return fetch({
        url: prefix+'/xkyActivityRegistrationSigninRecord/registrationAndCheckInRecord',
        method: 'post',
        data:subData
    });
}
export function addTopicNews(subData) {
    return fetch({
        url: prefix+'/xkyTopicNews/addTopicNews',
        method: 'post',
        data:subData
    });
}
export function deleteUser(subData) {
    return fetch({
        url: prefix+'/xkyUserInfo/deleteUser',
        method: 'post',
        data:subData
    });
}
export function deletePointsRecord(subData) {
    return fetch({
        url: prefix+'/xkyPointsRecord/deletePointsRecord',
        method: 'post',
        data:subData
    });
}
export function deleteOrderRecord(subData) {
    return fetch({
        url: prefix+'/xkyOrderRecord/deleteOrderRecord',
        method: 'post',
        data:subData
    });
}
export function deleteActivity(subData) {
    return fetch({
        url: prefix+'/xkyActivityInformation/deleteActivity',
        method: 'post',
        data:subData
    });
}
export function deleteActivityProcedure(subData) {
    return fetch({
        url: prefix+'/xkyActivityProcedure/deleteActivityProcedure',
        method: 'post',
        data:subData
    });
}
export function deleteXr(subData) {
    return fetch({
        url: prefix+'/xkyActivityRegistrationSigninRecord/deleteXr',
        method: 'post',
        data:subData
    });
}
export function deleteTopicNews(subData) {
    return fetch({
        url: prefix+'/xkyTopicNews/deleteTopicNews',
        method: 'post',
        data:subData
    });
}
export function modifyUser(subData) {
    return fetch({
        url: prefix+'/xkyUserInfo/modifyUser',
        method: 'post',
        data:subData
    });
}
export function updateTopicNews(subData) {
    return fetch({
        url: prefix+'/xkyTopicNews/updateTopicNews',
        method: 'post',
        data:subData
    });
}
export function updatePointsRecord(subData) {
    return fetch({
        url: prefix+'/xkyPointsRecord/updatePointsRecord',
        method: 'post',
        data:subData
    });
}
export function updateOrderRecord(subData) {
    return fetch({
        url: prefix+'/xkyOrderRecord/updateOrderRecord',
        method: 'post',
        data:subData
    });
}
export function userActivityModification(subData) {
    return fetch({
        url: prefix+'/xkyActivityInformation/userActivityModification',
        method: 'post',
        data:subData
    });
}
export function updateActivityProcedure(subData) {
    return fetch({
        url: prefix+'/xkyActivityProcedure/updateActivityProcedure',
        method: 'post',
        data:subData
    });
}
export function updateXr(subData) {
    return fetch({
        url: prefix+'/xkyActivityRegistrationSigninRecord/updateXr',
        method: 'post',
        data:subData
    });
}
export function points(subData) {
    return fetch({
        url: prefix+'/dictionary/points',
        method: 'post',
        data:subData
    });
}
export function addPoints(subData) {
    return fetch({
        url: prefix+'/dictionary/addPoints',
        method: 'post',
        data:subData
    });
}
export function updatePoints(subData) {
    return fetch({
        url: prefix+'/dictionary/updatePoints',
        method: 'post',
        data:subData
    });
}
export function deletePoints(subData) {
    return fetch({
        url: prefix+'/dictionary/deletePoints',
        method: 'post',
        data:subData
    });
}
export function systemUserChangePassword(subData) {
    return fetch({
        url: prefix+'/systemAccount/systemUserChangePassword',
        method: 'post',
        data:subData
    });
}
export function administratorList(subData) {
    return fetch({
        url: prefix+'/systemAccount/administratorList',
        method: 'post',
        data:subData
    });
}
export function queryPointsChange(subData) {
    return fetch({
        url: prefix+'/xkyApplicationChangePoints/queryPointsChange',
        method: 'post',
        data:subData
    });
}
export function approvePointsChange(subData) {
    return fetch({
        url: prefix+'/xkyApplicationChangePoints/approvePointsChange',
        method: 'post',
        data:subData
    });
}
export function integralChangeStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/integralChangeStatus',
        method: 'post',
        data:subData
    });
}
export function addIntegralChangeStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/addIntegralChangeStatus',
        method: 'post',
        data:subData
    });
}
export function updateIntegralChangeStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/updateIntegralChangeStatus',
        method: 'post',
        data:subData
    });
}
export function deleteIntegralChangeStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteIntegralChangeStatus',
        method: 'post',
        data:subData
    });
}
export function operationLog(subData) {
    return fetch({
        url: prefix+'/xkyOperationLog/queryOperationLog',
        method: 'post',
        data:subData
    });
}
export function registrationStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/registrationStatusDictionary',
        method: 'post',
        data:subData
    });
}
export function addRegistrationStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/addXkyDictionaryRegistrationStatus',
        method: 'post',
        data:subData
    });
}
export function upRegistrationStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/updateDictionaryRegistrationStatus',
        method: 'post',
        data:subData
    });
}
export function deRegistrationStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteDictionaryRegistrationStatus',
        method: 'post',
        data:subData
    });
}

export function dictionaryUserStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/dictionaryUserStatus',
        method: 'post',
        data:subData
    });
}
export function insertDictionaryUserStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/insertDictionaryUserStatus',
        method: 'post',
        data:subData
    });
}
export function updateDictionaryUserStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/updateDictionaryUserStatus',
        method: 'post',
        data:subData
    });
}
export function deleteDictionaryUserStatus(subData) {
    return fetch({
        url: prefix+'/dictionary/deleteDictionaryUserStatus',
        method: 'post',
        data:subData
    });
}

//权限列表
export function listAllPermission(){
    return fetch({
        url:prefix+'/systemAccount/listAllPermission',
        method:'get'
    })
}
// 角色权限
export function queryRoleCompetenceList(subData) {
    return fetch({
        url: prefix+'/systemAccount/queryRoleCompetenceList?'+subData,
        method: 'get',
    });
}
// 修改角色权限
export function modifyRolePermission(subData) {
    return fetch({
        url: prefix+'/systemAccount/modifyRolePermission?'+subData,
        method: 'get',
    });
}
//角色列表
export function listRole(){
    return fetch({
        url:prefix+'/systemAccount/listRole',
        method:'get'
    })
}
// 新增角色
export function addRoleInfo(subData) {
    return fetch({
        url: prefix+'/systemAccount/addRoleInfo?'+subData,
        method: 'get',
    });
}
//删除角色
export function deleteRole(subData){
    return fetch({
        url:prefix+'/systemAccount/deleteRole',
        method:'post',
        data:subData
    })
}
//激活人员
export function userActive(subData) {
    return fetch({
        url: prefix+'/systemAccount/activationUser',
        method: 'post',
        data: subData
    });
}



//删除人员
export function deleteBackstageUser(subData) {
    return fetch({
        url: prefix+'/systemAccount/deleteBackstageUser',
        method: 'post',
        data: subData
    });
}
//停止人员
export function stopBackstageUser(subData) {
    return fetch({
        url: prefix+'/systemAccount/stopBackstageUser',
        method: 'post',
        data: subData
    });
}
//客户界面列表信息
export function backstageUserList(subData) {
    return fetch({
        url: prefix+'/systemAccount/backstageUserList?'+subData,
        method: 'get',
    });
}
//新增人员
export function addBackstageUser(subData) {
    return fetch({
        url: prefix + '/systemAccount/addBackstageUser',
        method: 'post',
        data: subData
    });
}
//修改人员
export function updateBackstageUser(subData) {
    return fetch({
        url: prefix + '/systemAccount/updateBackstageUser',
        method: 'post',
        data: subData
    });
}
export function exportActivityProcedure(subData) {
    return fetch({
        url: prefix + '/xkyActivityProcedure/exportActivityProcedure',
        method: 'post',
        data: subData
    });
}
export function livebroadcastQuery(subData) {
    return fetch({
        url: prefix+'/livebroadcast/livebroadcastQuery?'+subData,
        method: 'get',
    });
}
export function addLivebroadcast(subData) {
    return fetch({
        url: prefix + '/livebroadcast/addLivebroadcast',
        method: 'post',
        data: subData
    });
}
export function upLivebroadcast(subData) {
    return fetch({
        url: prefix + '/livebroadcast/upLivebroadcast',
        method: 'post',
        data: subData
    });
}
export function deLivebroadcast(subData) {
    return fetch({
        url: prefix+'/livebroadcast/deLivebroadcast?'+subData,
        method: 'get',
    });
}