import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    img:""
  },
  mutations: {
    increment (state,payload) {
      state.img=payload.imgUrl
    }
  }
})
export default store
