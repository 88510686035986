export function setDate(data){
    if(data){
        var date=new Date(data)
        var Y = date.getFullYear() + '-';
        var  M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate()<10 ? '0'+date.getDate():date.getDate()) + '';
        return Y+M+D;
    }else {
        return "";
    }

}
export function setTime(data){
    if (data === null || data ===''){
        return null;
    }
    var date=new Date(data)
    var Y = date.getFullYear() + '-';
    var  M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate()<10 ? '0'+date.getDate():date.getDate()) + ' ';
    var h = (date.getHours()<10 ? '0'+date.getHours():date.getHours())+ ':';
    var m = (date.getMinutes()<10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
    var s = date.getSeconds()<10 ? '0'+date.getSeconds():date.getSeconds();
    return Y+M+D+h+m+s;
}
export function setHours(data){
    var date=new Date(data)
    var h = (date.getHours()<10 ? '0'+date.getHours():date.getHours())+ ':';
    var m = (date.getMinutes()<10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
    var s = date.getSeconds()<10 ? '0'+date.getSeconds():date.getSeconds();
    return h+m+s;
}
export function isEmpty(obj){
    if(typeof obj == "undefined" || obj == null || obj == ""){
        return false;
    }else{
        return true;
    }
}