<template>
  <div class="home">
    <div>
      <el-menu
          class="el-menu-vertical-demo"
          background-color="#373737"
          text-color="#D6D6D6"
          active-text-color="#D6D6D6"
          router v-bind:default-active="$route.path">
        <div class="head-img">
          <img src="@/assets/logo_a.png" alt="">
        </div>
        <el-menu-item index="/userInfo">
          <i class="el-icon-user-solid"></i>
          <span slot="title">用户信息</span>
        </el-menu-item>
        <el-menu-item index="/pointsRecord">
          <i class="el-icon-s-order"></i>
          <span slot="title">积分记录</span>
        </el-menu-item>
        <el-menu-item index="/pointsChange">
          <i class="el-icon-finished"></i>
          <span slot="title">积分变更</span>
        </el-menu-item>
        <el-menu-item index="/orderRecord">
          <i class="el-icon-document-copy"></i>
          <span slot="title">订单记录</span>
        </el-menu-item>
        <el-menu-item index="/activityInformation">
          <i class="el-icon-document"></i>
          <span slot="title">活动信息</span>
        </el-menu-item>
        <el-menu-item index="/activityProcedure">
          <i class="el-icon-files"></i>
          <span slot="title">活动过程</span>
        </el-menu-item>
        <el-menu-item index="/livebroadcast">
          <i class="el-icon-files"></i>
          <span slot="title">直播</span>
        </el-menu-item>
        <el-menu-item index="/activityRegistrationSigninRecord">
          <i class="el-icon-s-check"></i>
          <span slot="title">报名签到记录</span>
        </el-menu-item>
        <el-menu-item index="/topicNews">
          <i class="el-icon-s-order"></i>
          <span slot="title">主题新闻</span>
        </el-menu-item>
        <el-menu-item index="/log">
          <i class="el-icon-s-order"></i>
          <span slot="title">日志</span>
        </el-menu-item>
        <el-menu-item index="/dictionary">
          <i class="el-icon-s-grid"></i>
          <span slot="title">字典</span>
        </el-menu-item>
        <el-menu-item index="/role">
          <i class="el-icon-s-grid"></i>
          <span slot="title">角色</span>
        </el-menu-item>
        <el-menu-item index="/crew">
          <i class="el-icon-s-grid"></i>
          <span slot="title">账户</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="home-right">
      <div class="home-top">
        <div>
          <el-dropdown @command="handleCommand">
            <div class="user-center" split-button style="text-align: right">
              <span>账户：{{systemUser}}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-lock" command="b">设置密码</el-dropdown-item>
              <el-dropdown-item icon="el-icon-back" command="c">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="home-main">
        <router-view/>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialog" width="400px">
      <el-form :model="form" label-width="100px" ref="form">
        <el-form-item label="原密码" prop="oldPwd">
          <el-input v-model="form.oldPwd" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="form.newPwd" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input v-model="form.confirmPwd" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pwdSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {systemUserChangePassword} from "@/common/js/index.js"

export default {
  name: 'home',
  data() {
    return {
      systemUser: localStorage.getItem('systemUser'),
      systemUserId: localStorage.getItem('systemUserId'),
      dialog: false,
      form: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: ""
      },
    }
  },
  computed: {},
  methods: {
    pwdSubmit(id){
      if (this.form.newPwd === this.form.confirmPwd) {
        let subData = "password=" +  this.form.newPwd + "&oldPassword=" +  this.form.oldPwd+ "&id=" +  this.systemUserId
        systemUserChangePassword(subData).then(res => {
          if (res) {
            this.dialog = false;
            this.$message.success("修改密码成功")
          }
        })
      } else {
        this.$message.error("两次输入的新密码不一致")
      }
    },
    handleCommand(command) {
      switch (command) {
        case 'b':
          this.dialog = true;
          break;
        case 'c':
          this.loginOut();
          break;
        default:
          break;
      }
    },
    loginOut() {
      this.$message.success('退出成功');
      localStorage.setItem("token", "");
      this.$router.push({
        name: 'login'
      })
    },
  },
  created() {

  },
  beforeDestroy() {
  }
}
</script>
<style scoped lang="scss">
.home {
  display: -webkit-flex; /* Safari */
  display: flex;
  height: 100%;
  /*border: 1px solid red;*/
}

.el-menu-vertical-demo {
  height: 100%;
  width: 138px;
  overflow: hidden;
}

.home-right {
  width: calc(100% - 140px);
  height: 100%;
}

.home-top {
  border-bottom: 1px solid #DDD;
  height: 50px;
  width: 100%;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  padding: 0 120px 0 1200px;

}

.home-main {
  width: 100%;
  height: calc(100% - 50px);
  /* border: 1px solid blue;*/
}

.el-menu-item.is-active {
  background-color: #409EFF !important;
}

.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  width: 120px;
}

.head-img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.user-center {
  color: #409EFF;
  font-size: 28px;
  height: 40px;
  /*background: #4A7DFA;*/
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  /* border-radius: 6px;*/
  margin-top: 5px;
  cursor: pointer;

  span {
    margin-top: 7px;
  }
}

.user-center .user-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  margin: 5px 20px 0 0;
}

.el-dropdown-menu {
  width: 160px;
}

.notice {
  border-top-left-radius: 3px;
}
</style>
