<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">

        <el-form-item>
          <el-select v-model="pointsTypeId" clearable placeholder="积分类型">
            <el-option
                v-for="item in pointsType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="searchForm" placeholde1r="请输入姓名" style="width:400px"
                    @keyup.enter.native="queryPointsRecord"
                    clearable></el-input>
          <el-button type="primary" @click="queryPointsRecord()">搜索</el-button>
          <el-button type="success" @click="addPageButton()">新增</el-button>
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>

        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="pointsRecord"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="用户"
        >
        </el-table-column>
        <el-table-column
            prop="pointsType"
            label="积分类型"
        >
        </el-table-column>
        <el-table-column
            prop="integralTime"
            label="积分时间"
        >
        </el-table-column>
        <el-table-column
            prop="integralChange"
            label="积分变化"
        >
        </el-table-column>
        <el-table-column
            prop="pointsBeforeChange"
            label="变化前积分"
        >
        </el-table-column>
        <el-table-column
            prop="operatingAccountName"
            label="操作账户"
        >
        </el-table-column>
        <el-table-column
            prop="operatingStatus"
            label="操作状态"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="updateButton(scope.row.id)">空投</el-button>
            <el-button type="danger" @click="deletePointsRecord(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.name" @focus="userClickOn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="积分变化 : " label-width="100px" prop="integralChange">
                <el-input v-model="ruleForm.integralChange"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="积分时间 : " label-width="100px"  prop="integralTime">
<!--                <el-input v-model="ruleForm.integralTime"></el-input>-->
                <el-date-picker
                    v-model="ruleForm.integralTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="积分类型 : " label-width="100px" prop="integralType">
                <el-select v-model="ruleForm.integralType" clearable >
                  <el-option
                      v-for="item in pointsType"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="积分状态 : " label-width="100px" prop="operatingStatus">-->
<!--                <el-input v-model="ruleForm.operatingStatus"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="空投" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.name" @focus="userClickOn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="积分变化 : " label-width="100px" prop="integralChange">
                <el-input v-model="ruleForm.integralChange"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="积分时间 : " label-width="100px"  prop="integralTime">
                <!--                <el-input v-model="ruleForm.integralTime"></el-input>-->
                <el-date-picker
                    v-model="ruleForm.integralTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>

            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="积分类型 : " label-width="100px" prop="integralType">-->
<!--                <el-select v-model="ruleForm.integralType" clearable >-->
<!--                  <el-option-->
<!--                      v-for="item in pointsType"-->
<!--                      :key="item.id"-->
<!--                      :label="item.name"-->
<!--                      :value="item.id"-->
<!--                      :disabled="item.disabled">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="积分状态 : " label-width="100px" prop="operatingStatus">-->
<!--                <el-input v-model="ruleForm.operatingStatus"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updatePointsRecord()">修改积分</el-button>
        </div>
      </el-dialog>
      <el-dialog title="用户列表" :visible.sync="userPage" width="1500px">
        <el-input v-model="userForm" placeholder="请输入姓名、微信号、微信手机号、真实姓名" style="width:400px"
                  @keyup.enter.native="queryUser"
                  clearable></el-input>
        <el-table
            border
            :header-cell-style="{background:'#dee2ec', color: '#666666'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            :data="userInfo"
            stripe
            max-height="750"
            style="width: 100%;margin-bottom:20px">
          <el-table-column
              prop="id"
              label="用户ID"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="wechatPhone"
              label="微信手机号"
              width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
          >
          </el-table-column>
          <el-table-column
              prop="membershipLevelId"
              label="会员等级"
          >
          </el-table-column>
          <el-table-column
              prop="sex"
              label="性别"
          >
          </el-table-column>
          <el-table-column
              prop="industrySectorId"
              label="行业领域"
          >
          </el-table-column>
          <el-table-column
              prop="affiliatedCompany"
              label="所属企业"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="info" @click="chooseUser(scope.row.id,scope.row.name)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: center"
                       background
                       @size-change="handleSizeChange2"
                       @current-change="handleCurrentChange2"
                       :current-page="currentPage2"
                       :page-sizes="pagesizes2"
                       :page-size="pagesize2"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="all2">
        </el-pagination>
      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>1.积分记录页</h2>
          *本页用于对用户积分记录信息进行维护。
          <br>
          <h3> 搜索：在上方搜索栏中，选择积分类型，输入用户姓名可以进行数据搜索和筛选。<br>
            新增：在上方搜索栏旁有“新增”按钮，点击后进入新增页面<br>
            修改：在每条记录右侧有“修改”按钮，点击后进入用户积分记录详情修改页面<br>
            删除：在每条记录右侧有“删除”按钮，点击后进入积分详情删除页面，点击后会弹出确认窗口，按“确认”完成删除。<br></h3>
          <h2>2.积分记录新增和修改页</h2>
          *本页用于对积分记录进行信息维护。
          <br>
          <h3> 必填属性：有红色*的为必填属性。<br>
            手动修改后的信息，不会直接生效，请在积分变更审批页中让主管进行审批<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  pointsType,
  queryPointsRecord,
  addPointsRecord, deletePointsRecord, updatePointsRecord, queryUser
} from "@/common/js/index.js"
import {setTime, setDate, isEmpty} from "@/common/js/util.js";

export default {
  name: "pointsRecord",
  data() {
    return {
      guidePage:false,
      pointsRecord: [],
      pointsType:[],
      pointsTypeId:'',
      searchForm: '',
      all: 0,
      currentPage: 1,
      loading: false,
      addPage:false,
      updatePage:false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        id:null,
        userId: null,
        name: null,
        integralType: '',
        integralTime: null,
        integralChange: null,
        operatingStatus: null,
      },
      rules: {
        userId: [{required: true, message: '请选择用户',trigger: 'blur'}],
        integralType: [{required: true, message: '请选择积分类型',trigger: 'blur'}],
        integralTime: [{required: true, message: '请选择积分时间', trigger: 'blur'}],
        integralChange: [{required: true,message: '请填写积分变化',  trigger: 'blur'}]
      },
      all2: 0,
      currentPage2: 1,
      pagesize2: 20,
      pagesizes2: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      userPage: false,
      userForm: '',
      userInfo:[]
    }
  },
  methods: {
    userClickOn(){
      this.queryUser();
      this.userPage = true;
    },
    chooseUser(id,name){
      this.ruleForm.name = name;
      this.ruleForm.userId = id;
      this.userPage = false;
    },
    queryUser() {
      let subData = 'currPage=' + this.currentPage2
          + '&pageSizePara=' + this.pagesize2
          + '&name=' + this.searchForm
      queryUser(subData).then(res => {
        if (res) {
          this.userInfo = res.data
          if (res.data.length !== 0) {
            this.all2 = res.data[0].totalCount
          } else {
            this.all2 = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
    },
    handleSizeChange2(val) {
      this.pagesize2 = val
    },
    updateButton(id){
      this.updatePage = true;
      for (let i = 0; i < this.pointsRecord.length; i++) {
        if (id === this.pointsRecord[i].id){
          this.ruleForm = this.pointsRecord[i]
          // this.ruleForm.integralType = 2
        }
      }
    },
    updatePointsRecord(){
      let subData = this.getFormData()
      updatePointsRecord(subData).then(res => {
        if (res) {
          this.$message.success('积分空投申请已提交，请进入积分空投页面进行审核');
          this.queryPointsRecord()
          this.updatePage = false
        }

      })
    },
    deletePointsRecord(id){
      this.$confirm('此操作将永久删除该积分, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deletePointsRecord(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.queryPointsRecord()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton(){
      this.addPage = true
      this.ruleForm = {
        id:null,
        userId: null,
        name: null,
        integralType: null,
        integralTime: null,
        integralChange: null,
        operatingStatus: null,
      };
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.userId) && subData.append("userId", this.ruleForm.userId);
      isEmpty(this.ruleForm.integralType) && subData.append("integralType", this.ruleForm.integralType);
      isEmpty(this.ruleForm.integralTime) && subData.append("addIntegralTime", setTime(this.ruleForm.integralTime));
      isEmpty(this.ruleForm.integralChange) && subData.append("integralChange", this.ruleForm.integralChange);
      isEmpty(this.ruleForm.operatingStatus) && subData.append("operatingStatus", this.ruleForm.operatingStatus);
      return subData;
    },
    add(ruleForm){

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData()
          addPointsRecord(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.queryPointsRecord()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    selectPointsType() {
      pointsType().then(res => {
        if (res) {
          this.pointsType = res.data
          for (let i = 0; i < this.pointsType.length; i++) {
              if (this.pointsType[i].id ===4){
                this.pointsType[i].disabled = true
              }
          }
        }
      })
    },
    queryPointsRecord() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
          + '&integralType=' + this.pointsTypeId
      queryPointsRecord(subData).then(res => {
        if (res) {
          this.pointsRecord = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.queryPointsRecord();
    this.selectPointsType();
  },
  watch: {
    currentPage: function () {
      this.queryPointsRecord()
    },
    pagesize: function () {
      this.queryPointsRecord()
    },
    pagesize2: function () {
      this.queryUser()
    },
    currentPage2: function () {
      this.queryUser()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
