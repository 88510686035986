<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">

        <el-form-item>
          <el-select v-model="pointsTypeId" clearable placeholder="积分类型" @change="queryPointsChange()">
            <el-option
                v-for="item in pointsType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="integralChangeStatusId" clearable placeholder="积分空投状态"  @change="queryPointsChange()">
            <el-option
                v-for="item in integralChangeStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="searchForm" placeholde1r="请输入姓名" style="width:400px"
                    @keyup.enter.native="queryPointsChange"
                    clearable></el-input>
          <el-button type="primary" @click="queryPointsChange()">搜索</el-button>
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
          :data="pointsChange"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="用户"
        >
        </el-table-column>
        <el-table-column
            prop="pointsType"
            label="积分类型"
        >
        </el-table-column>
        <el-table-column
            prop="integralTime"
            label="积分时间"
        >
        </el-table-column>
        <el-table-column
            prop="rawPoints"
            label="原始积分"
        >
        </el-table-column>
        <el-table-column
            prop="integralChange"
            label="积分变更"
        >
        </el-table-column>
        <el-table-column
            prop="submitName"
            label="提交账号"
        >
        </el-table-column>
        <el-table-column
            prop="approvalName"
            label="审批账号"
        >
        </el-table-column>
        <el-table-column
            prop="integralChangeStatusName"
            label="申请状态"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope" v-if="scope.row.applicationStatus==='1'">
            <el-button type="primary"size="medium"  @click="approveButton(scope.row.id,scope.row.printsRecordId,scope.row.userId,scope.row.integralChange,scope.row.rawPoints)">审批</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="审批" :visible.sync="approvePage" width="500px">
        <div slot="footer" class="dialog-footer">
          <el-button   @click="approvePage = false">取消</el-button>
          <el-button type="danger"  @click="approve(2)">拒绝</el-button>
          <el-button type="primary" @click="approve(1)">通过</el-button>
        </div>
      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>积分记录审批页</h2>
          本页用于对用户积分记录信息进行审批。
          <br>
          <h3> 搜索：在上方搜索栏中，选择积分记录审批状态，输入用户姓名、申请账号、可以进行数据搜索和筛选。<br>
            审批：在需要审批的每条记录右侧"审批按钮"，点击后会弹出窗口 有“通过”和“拒绝”按钮，点击进行审批。<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  pointsType,
  queryPointsChange,
  approvePointsChange, integralChangeStatus
} from "@/common/js/index.js"
import {setTime, setDate, isEmpty} from "@/common/js/util.js";

export default {
  name: "pointsChange",
  data() {
    return {
      guidePage:false,
      pointsChange: [],
      pointsType:[],
      integralChangeStatus:[],
      pointsTypeId:'',
      integralChangeStatusId:'',
      searchForm: '',
      all: 0,
      currentPage: 1,
      loading: false,
      addPage:false,
      updatePage:false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        id:null,
        userId: null,
        name: null,
        integralType: '',
        integralTime: null,
        integralChange: null,
        operatingStatus: null,
      },
      rules: {
        userId: [{required: true, trigger: 'blur'}],
        integralType: [{required: true, trigger: 'blur'}],
        integralTime: [{required: true,  trigger: 'blur'}],
        integralChange: [{required: true,  trigger: 'blur'}],
        operatingStatus: [{required: true,  trigger: 'blur'}],
      },
      approvePage:false,
      approveId:null,
      integralChange:0,
      rawPoints:0,
      printsRecordId:null,
      userId:null,
    }
  },
  methods: {
    approve(type){
      let subData = "id="+this.approveId
      if (type ===1){
        subData += "&applicationStatus=2"+"&integralChange="+this.integralChange+"&printsRecordId="+this.printsRecordId+"&userId="+this.userId

      }else if (type ===2){
        subData += "&applicationStatus=3"
      }
      approvePointsChange(subData).then(res => {
        if (res) {
          this.queryPointsChange()
          this.approvePage = false
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          });
        }

      })
    },
    approveButton(id,printsRecordId,userId,integralChange,rawPoints){
      this.approveId = id
      this.integralChange = integralChange-rawPoints
      this.printsRecordId = printsRecordId
      this.userId = userId
      this.approvePage = true
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.userId) && subData.append("userId", this.ruleForm.userId);
      isEmpty(this.ruleForm.integralType) && subData.append("integralType", this.ruleForm.integralType);
      isEmpty(this.ruleForm.integralTime) && subData.append("addIntegralTime", setTime(this.ruleForm.integralTime));
      isEmpty(this.ruleForm.integralChange) && subData.append("integralChange", this.ruleForm.integralChange);
      isEmpty(this.ruleForm.operatingStatus) && subData.append("operatingStatus", this.ruleForm.operatingStatus);
      return subData;
    },
    selectPointsType() {
      pointsType().then(res => {
        if (res) {
          this.pointsType = res.data
        }
      })
    },
    queryPointsChange() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
          + '&integralType=' + this.pointsTypeId
          + '&integralChangeStatusId=' + this.integralChangeStatusId
      queryPointsChange(subData).then(res => {
        if (res) {
          this.pointsChange = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

    selectIntegralChangeStatus() {
      integralChangeStatus().then(res => {
        if (res) {
          this.integralChangeStatus = res.data
        }
      })
    },
  },
  created() {
    this.queryPointsChange();
    this.selectPointsType();
    this.selectIntegralChangeStatus();
  },
  watch: {
    currentPage: function () {
      this.queryPointsChange()
    },
    pagesize: function () {
      this.queryPointsChange()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
