// axios二次封装
import axios from 'axios'
import that from '@/main.js'
import { Message,MessageBox } from 'element-ui';
import qs from 'qs'
// create an axios instance
const fetch = axios.create({
    baseURL:process.env.NODE_ENV ==='development'? "http://localhost:8080":"https://www.scprehabilitation.com/",
    // baseURL:'',
    timeout: 50000,// request timeout
    xhrFields: {
        withCredentials: true
    },
})
// request请求拦截器
fetch.interceptors.request.use(
    config => {
        const token =localStorage.getItem("token");
        token && (config.headers.token = token);
        return config
    }, error => {
        Promise.reject(error);
    })

// respone interceptor响应拦截器
fetch.interceptors.response.use(
    response=>{
        if(response.status === 200&&response.data.retCode == 0){
            return response.data;
        }else if(response.status === 200&&response.data.retCode==20011){
            Message({
                message:'登录过期，请重新登录',
                type: 'error',
                duration: 5 * 1000
            })
            that.$router.push({
                name: 'login'
            })
        }else if(response.status === 200&&response.data.retCode==500112){
            Message({
                message:"您还没有登录，请您登录",
                type: 'error',
                duration: 5 * 1000
            })
            that.$router.push({
                name: 'login'
            })
        }else if(response.status === 200&&response.data.retCode==500113){
            Message({
                message:'登录失效，请重新登录',
                type: 'error',
                duration: 5 * 1000
            })
            that.$router.push({
                name: 'login'
            })
        }else if(response.status === 200&&response.data.retCode==500114){
            Message({
                message:'您的账户已被锁定，请联系管理员解锁',
                type: 'error',
                duration: 5 * 1000
            })
            that.$router.push({
                name: 'login'
            })
        }
        else if(response.data.retCode){
            Message({
                message:response.data.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    Message({
                        message:"错误请求",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                case 403:
                    Message({
                        message:"拒绝访问",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                case 404:
                    Message({
                        message:"请求错误,未找到该资源",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                case 405:
                    Message({
                        message:"请求方法未允许",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                case 408:
                    Message({
                        message:"请求超时",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                case 500:
                    Message({
                        message:"服务器端出错",
                        type: 'error',
                        duration: 5 * 1000
                    })
                    /*console.log("err",error);
                    console.log("error.response:",error.response);*/
                    /*if(error.response.data.status==500112){
                        Message({
                            message:"您还没有登录，请您登录",
                            type: 'error',
                            duration: 5 * 1000
                        })
                        that.$router.push({
                            name: 'login'
                        })
                    }else if(error.response.data.status==500113){
                        Message({
                            message:'登录失效，请重新登录',
                            type: 'error',
                            duration: 5 * 1000
                        })
                        that.$router.push({
                            name: 'login'
                        })
                    }*/
                    break;
                case 501:
                    error.message = '网络未实现'
                    break;
                case 502:
                    error.message = '网络错误'
                    break;
                case 503:
                    error.message = '服务不可用'
                    break;
                case 504:
                    error.message = '网络超时'
                    break;
                case 505:
                    error.message = 'http版本不支持该请求'
                    break;
                default:
            }
        }
        return Promise.reject(error)
    })

export default fetch
