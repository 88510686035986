<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">

        <el-form-item>
<!--          <el-input v-model="searchForm" placeholder="请输入模块名" style="width:400px"-->
<!--                    @keyup.enter.native="queryOperationLog"-->
<!--                    clearable></el-input>-->
          <el-select v-model="searchForm" clearable placeholder="模块" @change="queryOperationLog()">
            <el-option
                v-for="item in datas"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="accountType" clearable placeholder="帐户类型" @change="queryOperationLog()">
            <el-option
                v-for="item in accountTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="operationType" clearable placeholder="操作类型" @change="queryOperationLog()">
            <el-option
                v-for="item in operationTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>

          <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 230px">
          </el-date-picker>
          <el-button type="primary" @click="queryOperationLog()">搜索</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'1'}"
          :data="orderRecord"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="账户"
            width="100px"
        >
        </el-table-column>
        <el-table-column
            prop="roles"
            label="角色"
            width="150px"
        >
        </el-table-column>
        <el-table-column
            prop="accountInfo"
            label="账户信息"
            width="130px"
        >
        </el-table-column>
        <el-table-column
            prop="ip"
            label="IP地址"
            width="130px"
        >
        </el-table-column>
        <el-table-column
            prop="data"
            label="模块"
            width="130px"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="操作"
            width="130px"
        >
        </el-table-column>
        <el-table-column
            prop="operationtTime"
            label="操作时间"
            width="170px"
        >
        </el-table-column>
        <el-table-column
            prop="log"
            label="详情"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  operationLog
} from "@/common/js/index.js"
import {isEmpty, setDate, setTime} from '@/common/js/util.js'

export default {
  name: "orderRecord",
  data() {
    return {
      orderRecord: [],
      orderStatus:[],
      orderStatusId:'',
      date:null,
      all: 0,
      currentPage: 1,
      loading: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
      },
      userPage: false,
      userForm: '',
      userInfo:[],
      invoiceStatus:'',

      accountTypes: [{
        value: '运维'
      }, {
        value: '小程序'
      }],
      operationTypes: [{
        value: '新增'
      }, {
        value: '修改'
      }, {
        value: '删除'
      }],
      datas: [{
        value: '用户'
      },{
        value: '订单记录'
      },{
        value: '积分记录'
      },{
        value: '用户'
      }, {
        value: '活动'
      }, {
        value: '活动过程'
      }, {
        value: '报名签到记录'
      }],
      accountType:'运维',
      operationType:'',
      searchForm:''
    }
  },
  methods: {
    handleCurrentChange2(val) {
      this.currentPage2 = val
    },
    handleSizeChange2(val) {
      this.pagesize2 = val
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      return subData;
    },

    queryOperationLog() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&status=' + this.operationType
          + '&data=' + this.searchForm
          + '&accountInfo=' + this.accountType
      if (this.date != null) {
        let searchStartTime = setDate(this.date[0])
        let searchEndTime = setDate(this.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime + ' 23:59:59'
      }
      operationLog(subData).then(res => {
        if (res) {
          this.orderRecord = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.queryOperationLog();
  },
  watch: {
    currentPage: function () {
      this.queryOperationLog()
    },
    pagesize: function () {
      this.queryOperationLog()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
