<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">
        <el-form-item>
          <el-select v-model="status" clearable placeholder="直播状态" style="width: 120px;" @change="inquiryLivebroadcast">
            <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          &nbsp;
          <el-input v-model="searchForm" placeholder="请输入直播名" style="width:400px"
                    @keyup.enter.native="inquiryLivebroadcast"
                    clearable></el-input>
          <el-button type="primary" @click="inquiryLivebroadcast()">搜索</el-button>
          <el-button type="success" @click="addPageButton()">新增</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
          :data="livebroadcast"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="直播名">
        </el-table-column>
        <el-table-column
            prop="roomnumber"
            label="房间号"
            width="120px"
        >
        </el-table-column>
        <el-table-column
            prop="pagepathinstudio"
            label="直播间页面路径"
        >
        </el-table-column>
        <el-table-column
            prop="priority"
            label="优先级"
            width="120px"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="直播状态"
            width="120px"
        >
        </el-table-column>
        <el-table-column
            prop="startTime"
            label="开播时间"
            width="180px"
        >
        </el-table-column>
        <el-table-column prop="operation" width="200px" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="updateButton(scope.row)">修改</el-button>
            <el-button type="danger" @click="deleteXr(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播名 : " label-width="120px" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="房间号 : " label-width="120px">
                <el-input v-model="ruleForm.roomnumber"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播状态 : " label-width="120px" prop="registrationStatus">
                <el-select v-model="ruleForm.status" clearable placeholder="报名状态">
                  <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="优先级 : " label-width="120px" prop="priority">
                <el-input v-model="ruleForm.priority"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播时间 : " label-width="120px" prop="registrationStatus">
                <el-date-picker
                    v-model="ruleForm.startTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>

            </el-col>


          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="直播间页面路径 : " label-width="130px" prop="pagepathinstudio">
                <el-input v-model="ruleForm.pagepathinstudio"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播名 : " label-width="120px" prop="name">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="房间号 : " label-width="120px">
                <el-input v-model="ruleForm.roomnumber"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播状态 : " label-width="120px" prop="registrationStatus">
                <el-select v-model="ruleForm.status" clearable placeholder="报名状态">
                  <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="优先级 : " label-width="120px" prop="priority">
                <el-input v-model="ruleForm.priority"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="直播时间 : " label-width="120px" prop="registrationStatus">
                <el-date-picker
                    v-model="ruleForm.startTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>

            </el-col>


          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="直播间页面路径 : " label-width="130px" prop="pagepathinstudio">
                <el-input v-model="ruleForm.pagepathinstudio"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updateXr()">修改</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  registrationAndCheckInRecord,
  livebroadcastQuery,
  deleteXr,
  queryUser,
  activityQuery,
  activeState,
  registrationStatus,
  addLivebroadcast,
  upLivebroadcast, deLivebroadcast
} from "@/common/js/index.js"
import {isEmpty, setDate, setTime} from "@/common/js/util";

export default {
  name: "activityRegistrationSigninRecord",
  data() {
    return {
      guidePage: false,
      livebroadcast: [],
      searchForm: '',
      all: 0,
      currentPage: 1,
      loading: false,
      updatePage: false,
      addPage: false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        name: null,
        roomnumber: null,
        pagepathinstudio: null,
        priority: null,
        status: null,
        startTime: null,
      },
      rules: {
        name: [{required: true, message: '请填写直播名', trigger: 'blur'}],
        roomnumber: [{required: true, message: '请填写房间号', trigger: 'blur'}],
        pagepathinstudio: [{required: true, message: '请填写直播路径', trigger: 'blur'}],
        priority: [{required: true, message: '请填写优先级', trigger: 'blur'}],
        status: [{required: true, message: '请选择直播状态', trigger: 'blur'}],
      },

      registrationStatusList: [],

      statusList: [{
        value: '直播中',
        label: '直播中'
      }, {
        value: '未开始',
        label: '未开始'
      }, {
        value: '已结束',
        label: '已结束'
      }, {
        value: '禁播',
        label: '禁播'
      }, {
        value: '暂停',
        label: '暂停'
      }, {
        value: '异常',
        label: '异常'
      }, {
        value: '已过期',
        label: '已过期'
      }],
      status: ''
    }
  },
  methods: {
    inquiryLivebroadcast() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
          + '&status=' + this.status
      livebroadcastQuery(subData).then(res => {
        if (res) {
          this.livebroadcast = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.name) && subData.append("name", this.ruleForm.name);
      isEmpty(this.ruleForm.roomnumber) && subData.append("roomnumber", this.ruleForm.roomnumber);
      isEmpty(this.ruleForm.pagepathinstudio) && subData.append("pagepathinstudio", this.ruleForm.pagepathinstudio);
      isEmpty(this.ruleForm.priority) && subData.append("priority", this.ruleForm.priority);
      isEmpty(this.ruleForm.status) && subData.append("status", this.ruleForm.status);
      isEmpty(this.ruleForm.startTime) && subData.append("startTime2", setTime(this.ruleForm.startTime));
      return subData;
    },
    add(ruleForm) {

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData()
          addLivebroadcast(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.inquiryLivebroadcast()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateButton(row) {
      this.updatePage = true;
      this.ruleForm = row;
    },
    updateXr() {
      let subData = this.getFormData()
      upLivebroadcast(subData).then(res => {
        if (res) {
          this.$message.success('修改成功');
          this.inquiryLivebroadcast()
          this.updatePage = false
        }

      })
    },


    deleteXr(id) {
      this.$confirm('此操作将永久直播, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deLivebroadcast(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.inquiryLivebroadcast()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton() {
      this.addPage = true
      this.ruleForm = {
        name: null,
        roomnumber: null,
        pagepathinstudio: null,
        priority: null,
        status: null,
        startTime: null,
      };
    },


    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

  },
  created() {
    this.inquiryLivebroadcast();
  },
  watch: {
    currentPage: function () {
      this.inquiryLivebroadcast()
    },
    pagesize: function () {
      this.inquiryLivebroadcast()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
