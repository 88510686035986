<template>
  <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                view-class="p20-scrollbar__view">
    <div v-if="loading">
      <el-form style="margin-left: 30px;margin-top: 30px">

        <el-form-item>
          <el-select v-model="orderStatusId" clearable placeholder="订单状态">
            <el-option
                v-for="item in orderStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="invoiceStatus" clearable placeholder="发票状态">
            <el-option
                v-for="item in invoiceStatusList"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="searchForm" placeholde1r="请输入姓名" style="width:400px"
                    @keyup.enter.native="queryOrderRecord"
                    clearable></el-input>
          <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 230px">
          </el-date-picker>
          <el-button type="primary" @click="queryOrderRecord()">搜索</el-button>
<!--          <el-button type="success" @click="addPageButton()">新增</el-button>-->
          <el-button type="info" style="float: right;margin-right: 50px" @click="guidePage=true">指引</el-button>
        </el-form-item>


      </el-form>

      <el-table
          border
          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'1'}"
          :data="orderRecord"
          stripe
          max-height="700"
          style="width: 100%;margin-bottom:20px">
        <el-table-column
            prop="name"
            label="用户"
        >
        </el-table-column>
        <el-table-column
            prop="amountPaid"
            label="支付金额"
        >
        </el-table-column>
        <el-table-column
            prop="paymentRecordNumber"
            label="支付记录号"
        >
        </el-table-column>
        <el-table-column
            prop="orderStatus"
            label="状态"
        >
        </el-table-column>
        <el-table-column
            prop="invoiceStatus"
            label="发票状态"
        >
        </el-table-column>
        <el-table-column
            prop="paymentTime"
            label="支付时间"
            width="200px"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="confirmTime"-->
<!--            label="确认时间"-->
<!--        >-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="confirmAccount"-->
<!--            label="确认账号"-->
<!--        >-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="confitmResult"-->
<!--            label="确认结果"-->
<!--        >-->
<!--        </el-table-column>-->
        <el-table-column
            prop="confitmRemark"
            label="确认备注"
        >
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="280px">
          <template slot-scope="scope">
<!--            <el-button type="info" @click="detailedButton(scope.row.id)">详情</el-button>-->
            <el-button type="primary" @click="updateButton(scope.row.id)">修改</el-button>
            <el-button type="success" @click="invoicingButton(scope.row.id)">开票</el-button>
<!--            <el-button type="danger" @click="deleteOrderRecord(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: center"
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="pagesizes"
                     :page-size="pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="all">
      </el-pagination>
      <el-dialog title="详情" :visible.sync="detailedPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="支付金额 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.amountPaid"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态 : " label-width="100px" prop="statusId">
                <el-select v-model="ruleForm.statusId" clearable placeholder="订单状态">
                  <el-option
                      v-for="item in orderStatus"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="预定时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.scheduledTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.paymentTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付记录号 : " label-width="100px">
                <el-input v-model="ruleForm.paymentRecordNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.confirmTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="确认账号 : " label-width="100px">
                <el-input v-model="ruleForm.confirmAccount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认结果 : " label-width="100px">
                <el-input v-model="ruleForm.confitmResult"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="确认备注 : " label-width="100px">
                <el-input v-model="ruleForm.confitmRemark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

      </el-dialog>
      <el-dialog title="新增" :visible.sync="addPage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.name" @focus="userClickOn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="支付金额 : " label-width="100px">
                <el-input v-model="ruleForm.amountPaid"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态 : " label-width="100px" prop="statusId">
                <el-select v-model="ruleForm.statusId" clearable placeholder="订单状态">
                  <el-option
                      v-for="item in orderStatus"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="预定时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.scheduledTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.paymentTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付记录号 : " label-width="100px">
                <el-input v-model="ruleForm.paymentRecordNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.confirmTime"
                    type="datetime"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="确认账号 : " label-width="100px">
                <el-input v-model="ruleForm.confirmAccount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认结果 : " label-width="100px">
                <el-input v-model="ruleForm.confitmResult"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="确认备注 : " label-width="100px">
                <el-input v-model="ruleForm.confitmRemark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="addPage = false">取 消</el-button>
          <el-button type="primary" @click="add('ruleForm')">创 建</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改" :visible.sync="updatePage" width="1000px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户 : " label-width="100px"  prop="userId">
                <el-input v-model="ruleForm.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="支付金额 : " label-width="100px">
                <el-input v-model="ruleForm.amountPaid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态 : " label-width="100px" prop="statusId">
                <el-select v-model="ruleForm.statusId" clearable placeholder="订单状态">
                  <el-option
                      v-for="item in orderStatus"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="预定时间 : " label-width="100px">-->
<!--                <el-date-picker-->
<!--                    v-model="ruleForm.scheduledTime"-->
<!--                    type="datetime"-->
<!--                    placeholder="选择日期时间">-->
<!--                </el-date-picker>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="8">
              <el-form-item label="支付时间 : " label-width="100px">
                <el-date-picker
                    v-model="ruleForm.paymentTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    disabled>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="支付记录号 : " label-width="100px">
                <el-input v-model="ruleForm.paymentRecordNumber" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>

<!--            <el-col :span="12">-->
<!--              <el-form-item label="确认时间 : " label-width="100px">-->
<!--                <el-date-picker-->
<!--                    v-model="ruleForm.confirmTime"-->
<!--                    type="datetime"-->
<!--                    placeholder="选择日期时间">-->
<!--                </el-date-picker>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="确认账号 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.confirmAccount"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="确认结果 : " label-width="100px">-->
<!--                <el-input v-model="ruleForm.confitmResult"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row>
            <el-col :span="24">
              <el-form-item label="确认备注 : " label-width="100px">
                <el-input v-model="ruleForm.confitmRemark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="updatePage = false">取 消</el-button>
          <el-button type="primary" @click="updateOrderRecord()">修改订单记录</el-button>
        </div>
      </el-dialog>
      <el-dialog title="用户列表" :visible.sync="userPage" width="1500px">
        <el-input v-model="userForm" placeholder="请输入姓名、微信号、微信手机号、真实姓名" style="width:400px"
                  @keyup.enter.native="queryUser"
                  clearable></el-input>
        <el-table
            border
            :header-cell-style="{background:'#dee2ec', color: '#666666'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            :data="userInfo"
            stripe
            max-height="750"
            style="width: 100%;margin-bottom:20px">
          <el-table-column
              prop="id"
              label="用户ID"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名"
              width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="wechatPhone"
              label="微信手机号"
              width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
          >
          </el-table-column>
          <el-table-column
              prop="membershipLevelId"
              label="会员等级"
          >
          </el-table-column>
          <el-table-column
              prop="sex"
              label="性别"
          >
          </el-table-column>
          <el-table-column
              prop="industrySectorId"
              label="行业领域"
          >
          </el-table-column>
          <el-table-column
              prop="affiliatedCompany"
              label="所属企业"
          >
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="280px">
            <template slot-scope="scope">
              <el-button type="info" @click="chooseUser(scope.row.id,scope.row.name)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: center"
                       background
                       @size-change="handleSizeChange2"
                       @current-change="handleCurrentChange2"
                       :current-page="currentPage2"
                       :page-sizes="pagesizes2"
                       :page-size="pagesize2"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="all2">
        </el-pagination>


      </el-dialog>
      <el-dialog title="指引" :visible.sync="guidePage" width="1000px">
        <div>
          <h2>支付记录页</h2>
          *本页用于对支付记录进行信息维护。
          <br>
          <h3> 搜索：在上方搜索栏中，模糊输入相关信息，可以进行数据搜索和筛选。<br>
            修改：在每条记录右侧有“修改”按钮，点击后进入详情修改该页面，进行数据修改，支付记录只支持，状态修改和备注记录<br></h3>

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="guidePage = false">关 闭</el-button>
        </div>
      </el-dialog>
      <el-dialog title="开票" :visible.sync="invoicingPage" width="1200px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
          <el-row>
            <el-col :span="8">
              <el-form-item label="发票抬头 : " label-width="140px">
                <el-input v-model="ruleForm.invoiceHeader" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="抬头类型 : " label-width="140px">
                <el-input v-model="ruleForm.headupType" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="默认发票类型 : " label-width="140px">
                <el-input v-model="ruleForm.defaultInvoiceType" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="统一社会信用代码 : " label-width="140px">
                <el-input v-model="ruleForm.unifiedSocialCreditCode" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电子邮箱 : " label-width="140px">
                <el-input v-model="ruleForm.mailbox" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户银行名称 : " label-width="140px">
                <el-input v-model="ruleForm.bankName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="开户账号 : " label-width="140px">
                <el-input v-model="ruleForm.accountNumber" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="注册场所地址 : " label-width="140px">
                <el-input v-model="ruleForm.registeredPlaceAddress" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="注册固定电话 : " label-width="140px">
                <el-input v-model="ruleForm.registerLandline" disabled></el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="invoicingPage = false">取 消</el-button>
          <el-button type="primary" @click="invoicing()">已开票</el-button>
        </div>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>
<script>
import {
  addOrderRecord, deleteOrderRecord,
  orderStatus,
  queryOrderRecord, queryUser, updateOrderRecord
} from "@/common/js/index.js"
import {isEmpty, setDate, setTime} from '@/common/js/util.js'

export default {
  name: "orderRecord",
  data() {
    return {
      guidePage:false,
      orderRecord: [],
      orderStatus:[],
      orderStatusId:'',
      searchForm: '',
      date:null,
      all: 0,
      currentPage: 1,
      loading: false,
      addPage:false,
      updatePage:false,
      detailedPage:false,
      invoicingPage:false,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      ruleForm: {
        userId: null,
        name: null,
        statusId: '',
        scheduledTime: null,
        paymentTime: null,
        paymentRecordNumber: null,
        confirmTime: null,
        confirmAccount: null,
        confitmResult: null,
        confitmRemark: null,
        amountPaid:0,
        invoiceStatus:''
      },
      rules: {
        userId: [{required: true, trigger: 'blur'}],
        statusId: [{required: true, trigger: 'blur'}],
      },
      all2: 0,
      currentPage2: 1,
      pagesize2: 20,
      pagesizes2: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      userPage: false,
      userForm: '',
      userInfo:[],
      invoiceStatusList: [{
        value: '未开票'
      }, {
        value: '开票中'
      }, {
        value: '已开票'
      }],
      invoiceStatus:'',
    }
  },
  methods: {
    userClickOn(){
      this.queryUser();
      this.userPage = true;
    },
    chooseUser(id,name){
      this.ruleForm.name = name;
      this.ruleForm.userId = id;
      this.userPage = false;
    },
    queryUser() {
      let subData = 'currPage=' + this.currentPage2
          + '&pageSizePara=' + this.pagesize2
          + '&name=' + this.userForm
      queryUser(subData).then(res => {
        if (res) {
          this.userInfo = res.data
          if (res.data.length !== 0) {
            this.all2 = res.data.length
          } else {
            this.all2 = 0
          }
          this.loading = true
        }

      })

    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
    },
    handleSizeChange2(val) {
      this.pagesize2 = val
    },
    detailedButton(id){
      this.detailedPage = true;
      for (let i = 0; i < this.orderRecord.length; i++) {
        if (id === this.orderRecord[i].id){
          this.ruleForm = this.orderRecord[i]
        }
      }
    },
    updateButton(id){
      this.updatePage = true;
      for (let i = 0; i < this.orderRecord.length; i++) {
        if (id === this.orderRecord[i].id){
          this.ruleForm = this.orderRecord[i]
        }
      }
    },
    invoicingButton(id){
      this.invoicingPage = true;
      for (let i = 0; i < this.orderRecord.length; i++) {
        if (id === this.orderRecord[i].id){
          this.ruleForm = this.orderRecord[i]
        }
      }
    },
    updateOrderRecord(){
      let subData = this.getFormData()
      updateOrderRecord(subData).then(res => {
        if (res) {
          this.ruleForm = []
          this.$message.success('修改成功');
          this.queryOrderRecord()
          this.updatePage = false
        }

      })
    },
    invoicing(){
      this.ruleForm.invoiceStatus = "已开票"
      let subData = this.getFormData()
      updateOrderRecord(subData).then(res => {
        if (res) {
          this.ruleForm = []
          this.$message.success('开票成功');
          this.queryOrderRecord()
          this.invoicingPage = false
        }

      })
    },
    deleteOrderRecord(id){
      this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "id=" + id
        deleteOrderRecord(subData).then(res => {
          if (res) {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            });
            this.queryOrderRecord()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addPageButton(){
      this.addPage = true
      this.ruleForm =  {
        userId: null,
        name: null,
        statusId: '',
        scheduledTime: null,
        paymentTime: null,
        paymentRecordNumber: null,
        confirmTime: null,
        confirmAccount: null,
        confitmResult: null,
        confitmRemark: null,
        amountPaid:0,
      }
    },
    getFormData() {
      let subData = new FormData();
      isEmpty(this.ruleForm.id) && subData.append("id", this.ruleForm.id);
      isEmpty(this.ruleForm.userId) && subData.append("userId", this.ruleForm.userId);
      isEmpty(this.ruleForm.statusId) && subData.append("statusId", this.ruleForm.statusId);
      isEmpty(this.ruleForm.scheduledTime) && subData.append("addScheduledTime", setTime(this.ruleForm.scheduledTime));
      isEmpty(this.ruleForm.paymentTime) && subData.append("addPaymentTime", setTime(this.ruleForm.paymentTime));
      isEmpty(this.ruleForm.paymentRecordNumber) && subData.append("paymentRecordNumber", this.ruleForm.paymentRecordNumber);
      isEmpty(this.ruleForm.confirmTime) && subData.append("addConfirmTime", setTime(this.ruleForm.confirmTime));
      isEmpty(this.ruleForm.confirmAccount) && subData.append("confirmAccount", this.ruleForm.confirmAccount);
      isEmpty(this.ruleForm.confitmResult) && subData.append("confitmResult", this.ruleForm.confitmResult);
      isEmpty(this.ruleForm.confitmRemark) && subData.append("confitmRemark", this.ruleForm.confitmRemark);
      isEmpty(this.ruleForm.amountPaid) && subData.append("amountPaid", this.ruleForm.amountPaid);
      isEmpty(this.ruleForm.invoiceStatus) && subData.append("invoiceStatus", this.ruleForm.invoiceStatus);

      return subData;
    },
    add(ruleForm){

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let subData = this.getFormData()
          addOrderRecord(subData).then(res => {
            if (res) {
              this.$message.success('新增成功');
              this.queryOrderRecord()
              this.addPage = false
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    queryOrderRecord() {
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&name=' + this.searchForm
          + '&statusId=' + this.orderStatusId
          + '&invoiceStatus=' + this.invoiceStatus
      if (this.date != null) {
        let searchStartTime = setDate(this.date[0])
        let searchEndTime = setDate(this.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime + ' 23:59:59'
      }
      queryOrderRecord(subData).then(res => {
        if (res) {
          this.orderRecord = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
          this.loading = true
        }

      })

    },
    selectOrderStatus() {
      orderStatus().then(res => {
        if (res) {
          this.orderStatus = res.data
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.queryOrderRecord();
    this.selectOrderStatus();
  },
  watch: {
    currentPage: function () {
      this.queryOrderRecord()
    },
    pagesize: function () {
      this.queryOrderRecord()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.sel2 {
  width: 110px;
}

.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}

.line {
  width: 120px;
}

.line2 {
  width: 250px;
}
</style>
